import { useState } from "react";
import { GlobalStyles } from "../GlobalStyles";
import { toastError, toastPromise } from "../GlobalFunctions.ts";
import { supabase } from "../supabase";
import { useNavigate } from "react-router-dom";
import "../assets/css/brands.css";

export default function Brands() {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [size, setSize] = useState("");
    const [spend, setSpend] = useState("");
    const [buttonclicked, setButtonClicked] = useState(false);

    return (
        <div>
            <div
                style={{ height: "calc(100vh - 55.1px - 1vh)" }}
                className="!flex-row justify-between gap-[5vw] px-[5vw] items-center w-full h-[70%]"
            >
                <div id="imgcontainer" className="rounded-2xl relative w-1/2 flex items-center justify-center">
                    <img
                        src={require("../assets/images/creatorsettingsimage.jpeg")}
                        id="csimage"
                        className="h-[40vw] rounded-[50px] max-w-fit relative"
                        alt="creatorsettings"
                    />
                </div>
                <div id="firstpagetext" className="gap-[3vh]">
                    <p id="firstpagetitle" className=" font-bold italic text-5xl">
                        A Better Impact™ Platform
                    </p>
                    <p id="firstpagetitle" style={{ fontFamily: "Ivy Mode" }} className=" font-bold text-5xl">
                        Claim Your Spot!
                    </p>
                    <p className=" font-medium text-xl">
                        Are you a sustainable, clean energy brand looking for content creators to spread your message?
                        Claim your spot and get paired now!
                    </p>
                    <button
                        className="bg-white font-bold p-[1vw] w-[30%] text-base rounded-full min-w-fit"
                        onClick={() =>
                            document
                                .getElementById("secondpage-brands")
                                .scrollIntoView({ behavior: "smooth", block: "center" })
                        }
                    >
                        Book a call with a representative
                    </button>
                </div>
            </div>
            <div
                id="secondpage-brands"
                className="py-[5vh] px-[15vw] justify-center items-center w-full min-h-screen bg-white"
            >
                <div className="items-center justify-center customshadow w-full py-[5vh] px-[10vw] rounded-2xl">
                    <h1 className="mb-[.5vh] flex items-start justify-between w-full">Email</h1>
                    <input
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="mb-[2vh] customarea"
                    />
                    {/* Size Section */}
                    <h1 className="mb-[.5vh] flex items-start justify-between w-full">Size of Company</h1>
                    <select
                        className="w-full customarea mb-[2vh]"
                        value={size}
                        onChange={(e) => setSize(e.target.value)}
                    >
                        <option value="" disabled>
                            -Select an item-
                        </option>
                        <option value="Small">Small (1-10 employees)</option>
                        <option value="Medium">Medium (10-100 employees)</option>
                        <option value="Large">Large (100+ employees)</option>
                    </select>
                    {/* Monthly Spend Section */}
                    <h1 className="mb-[.5vh] flex items-start justify-between w-full">Monthly Social Media Spend</h1>
                    <select
                        className="w-3/4 customarea"
                        value={spend}
                        defaultValue=""
                        onChange={(e) => setSpend(e.target.value)}
                    >
                        <option value="" disabled>
                            -Select an item-
                        </option>
                        <option value="Less than $10k">&lt; $10k</option>
                        <option value="More than $10k">&gt; $10k</option>
                    </select>
                    <button
                        type="button"
                        className="actionbtn rounded-3xl !text-white !font-semibold w-2/6 !bg-[#0A2A3E]"
                        onClick={async () => {
                            setButtonClicked(true);
                            if (email === "" || size === "" || spend === "") {
                                toastError("Please fill out all fields!");
                            } else {
                                toastPromise(
                                    new Promise(async (resolve, reject) => {
                                        const { data, error } = await supabase
                                            .from("brands")
                                            .insert({ email, size, spend })
                                            .select("id")
                                            .single();
                                        if (error) {
                                            console.error("Error joining waitlist:", error);
                                            reject(error.message);
                                        } else {
                                            navigate("/postsignupbrand", { state: { uuid: data.id, role: "brand" } });
                                            resolve();
                                        }
                                    }),
                                    "Submitting...",
                                    "Submitted!"
                                );
                            }
                            setButtonClicked(false);
                        }}
                        disabled={buttonclicked}
                    >
                        SUBMIT
                    </button>
                </div>
            </div>
        </div>
    );
}
