export default function YouTubeIcon({ outercolor, innercolor }) {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.5654 12.2505C23.5654 18.7486 18.3936 24.001 12.0327 24.001C5.67179 24.001 0.5 18.7486 0.5 12.2505C0.5 5.75239 5.67179 0.5 12.0327 0.5C18.3936 0.5 23.5654 5.75239 23.5654 12.2505Z"
                fill={outercolor}
                stroke={outercolor}
            />
            <path d="M17.6826 10.6145C19.0159 11.3843 19.0159 13.3088 17.6826 14.0786L10.7163 18.1006C9.383 18.8704 7.71633 17.9082 7.71633 16.3686L7.71633 8.3246C7.71633 6.785 9.383 5.82274 10.7163 6.59255L17.6826 10.6145Z"
                fill={innercolor}
            />
        </svg>
    )
}