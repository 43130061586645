import React, { useState, useEffect, useContext, useRef } from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import PuffLoader from "react-spinners/PuffLoader";
import SignupScreen from "../../screens/SignUpScreen.jsx";
import { supabase } from "../../supabase.js";
import Header from "./Header.jsx";
import Footer from "./Footer.jsx";
import LoginScreen from "../../screens/LoginScreen.jsx";
import { checkMetadata } from "../../GlobalFunctions.ts";
import { AuthContext } from "../../App.js";
import Home from "../../screens/Home.jsx";
import "../../assets/css/routing.css";
import Brands from "../../screens/Brands.jsx";
import Creators from "../../screens/Creators.jsx";
import Resources from "../../screens/Resources.jsx";
import ForgotPassword from "../../screens/ForgotPassword.jsx";
import PostSignUp from "../../screens/PostSignUp.tsx";
import JobListing from "../../screens/JobListing.jsx";
import CreatorSettings from "../../screens/CreatorSettings.jsx";
import Contact from "../../screens/Contact.jsx";
// import Profile from "../../screens/Profile.jsx";
import Refer from "../../screens/Refer.jsx";
import MediaKit from "../../screens/MediaKit.jsx";
import Privacy from "../../screens/Privacy.jsx";
import Terms from "../../screens/Terms.jsx";
import PostSignUpBrand from "../../screens/PostSignUpBrand.tsx";

export default function Routing() {
    const [isLoading, setIsLoading] = useState(true);
    // session denotes whether user is logged in
    const [rolechecked, setRoleChecked] = useState(false);
    const { name, setName, session, setSession, sessionchecked, setSessionChecked, setUser, user } =
        useContext(AuthContext);

    useEffect(() => {
        async function initialCheckSession() {
            const {
                data: { session },
            } = await supabase.auth.getSession();
            setSession(session);
            setSessionChecked(true);
        }

        initialCheckSession();

        const {
            data: { subscription },
        } = supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session);
            setSessionChecked(true);
        });

        return () => subscription.unsubscribe();
    }, []);

    useEffect(() => {
        // Check session and role to determine if onboarding is filled
        if (session) {
            setIsLoading(true);
            (async function getRole() {
                const metadata = await checkMetadata();
                // console.log("metadata:", metadata);
                setUser((prev) => ({
                    ...prev,
                    ...metadata,
                    name: metadata.name,
                }));
                setIsLoading(false);
            })();
        }

        if (sessionchecked) {
            setIsLoading(false);
        }
    }, [session, sessionchecked]);

    const userRef = useRef(user);
    const listenerSetRef = useRef(false);

    useEffect(() => {
        userRef.current = user;
        if (user && !listenerSetRef.current) {
            // console.log("setting up channel");
            supabase
                .channel(user?.email)
                .on(
                    "postgres_changes",
                    {
                        event: "*",
                        schema: "public",
                        table: "users",
                        filter: "email=eq." + user.email,
                    },
                    async (payload) => setUser((prev) => ({ ...prev, ...payload.new }))
                )
                .subscribe();
            listenerSetRef.current = true;
        } else if (!user) {
            // console.log("removing all channels");
            supabase.removeAllChannels();
            listenerSetRef.current = false;
        }
    }, [user]);

    if (isLoading) {
        return <PuffLoader color={"#5D87FF"} loading={true} size={150} id="loader" />;
    }

    return (
        <BrowserRouter>
            {/* {session ? ( */}
            <div>
                <div id="maindiv" className="flex flex-col w-full">
                    <div className="w-full pt-[1vh]">
                        <Header />
                    </div>
                    <Routes>
                        <Route exact path="/" element={<Home />} />
                        <Route exact path="/brands" element={<Brands />} />
                        <Route exact path="creators" element={<Creators />} />
                        <Route exact path="resources" element={<Resources />} />
                        <Route path="*" element={<Navigate to={"/"} />} />
                        <Route path="/signin" element={<LoginScreen />} />
                        <Route path="/signup" element={<SignupScreen />} />
                        {/* <Route path='/refer/:refuuid' element={<SignupScreen />} /> */}
                        {/* pass refuuid to homepage */}
                        <Route path="/refer/:refuuid" element={<Refer />} />
                        <Route path="/joblisting" element={<JobListing />} />
                        <Route exact path="/postsignup" element={<PostSignUp />} />
                        <Route exact path="/postsignupbrand" element={<PostSignUpBrand />} />
                        <Route exact path="/contact" element={<Contact />} />
                        {/* <Route exact path="/profile" element={<Profile />} /> */}
                        <Route
                            exact
                            path="/creatorsettings"
                            element={session ? <CreatorSettings /> : <Navigate to="/" />}
                        />
                        <Route exact path="/mediakit/:creatorhandle" element={<MediaKit />} />
                        <Route exact path="/privacy" element={<Privacy />} />
                        <Route exact path="/terms" element={<Terms />} />
                        <Route exact path="/reset" element={<ForgotPassword />} />
                    </Routes>
                </div>
                {/* <Footer /> */}
            </div>
            {/* ) : (
        <Routes>
          <Route path='/signin' element={<LoginScreen />} />
          <Route path='/signup' element={<SignupScreen />} />
          <Route exact path='/brands' element={<Brands />} />
          <Route exact path='creators' element={<Creators />} />
          <Route exact path='resources' element={<Resources />} />
          <Route exact path='/postsignup' element={<PostSignUp />} />
          <Route path='*' element={<Navigate to={"/signin"} />} />
        </Routes>
      )} */}
        </BrowserRouter>
    );
}
