import { useLocation, useNavigate } from "react-router-dom";
import "../assets/css/postsignup.css";
import { toastSuccess } from "../GlobalFunctions.ts";

export default function PostSignUp() {
    const siteLink = window.location.href;
    const location = useLocation();
    const uuid = location.state.uuid;
    const role = location.state.role;
    const navigate = useNavigate();

    return (
        <div id="postsignupbox" className="flex flex-col items-center justify-center">
            <div
                id="innersignupbox"
                className="text-center flex flex-col items-center justify-center bg-white gap-[2vh] rounded-xl customshadow"
            >
                <p id="postsignuptitle" className="text-7xl text-center">
                    THANK YOU!
                </p>
                <p id="postsignupsubtitle">We're excited to meet with you!</p>
                <button
                    id="backtohomebutton"
                    className="actionbtn text-3xl text-center rounded-3xl !text-white !font-semibold w-[95%]"
                >
                    <a href="https://calendly.com/ourfuture-abetterinfluence/30min" target="_blank" rel="noreferrer">
                        Click here to book a call with us now!
                    </a>
                </button>
            </div>
        </div>
    );
}
