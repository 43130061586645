import "../../assets/css/header.css";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useContext, useEffect, useState } from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { IconButton, Popover } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { IoReorderThreeOutline } from "react-icons/io5";
import { AuthContext } from "../../App";

export default function Header() {
    const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1024px)" });
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const { session, user, logout } = useContext(AuthContext);

    const openpopover = Boolean(anchorEl);
    const id = openpopover ? "simple-popover" : undefined;

    return (
        <div id="navbar" className="flex flex-row justify-between items-center">
            {isDesktopOrLaptop ? (
                <div id="navleftlinks" className="!flex-row">
                    <Link to="/brands">Brands</Link>
                    <Link to="/creators">Creators</Link>
                    <Link to="/resources">Resources</Link>
                </div>
            ) : (
                <SwipeableDrawer
                    anchor={"left"}
                    open={drawerOpen}
                    onOpen={() => {}}
                    onClose={() => setDrawerOpen(false)}
                >
                    <div>
                        <div className="w-1/5">
                            <IconButton onClick={() => setDrawerOpen(false)} sx={{ justifyContent: "flex-start" }}>
                                <CloseIcon fontSize="small" sx={{ width: "100%", height: "100%", color: "black" }} />
                            </IconButton>
                        </div>
                        <div id="navleftlinks" className="flex flex-col mt-[2vh]">
                            <Link to="/brands">Brands</Link>
                            <Link to="/creators">Creators</Link>
                            <Link to="/resources">Resources</Link>
                        </div>
                    </div>
                </SwipeableDrawer>
            )}
            {isDesktopOrLaptop ? (
                <Link to="/" className="flex flex-row headertitle w-1/4">
                    <img src={require("../../assets/images/mainlogo.PNG")} className="" alt="logo" />
                </Link>
            ) : (
                <div className="flex flex-row !gap-[2vw] items-center">
                    <IoReorderThreeOutline
                        color="black"
                        size={"8vh"}
                        onClick={() => setDrawerOpen(true)}
                        style={{ minHeight: "40px", minWidth: "40px" }}
                    />
                    <Link to="/" className="headertitle max-w-[261px]">
                        <img src={require("../../assets/images/mainlogo.PNG")} className="" alt="logo" />
                    </Link>
                </div>
            )}
            <div className="!flex-row items-center justify-end min-w-[94px]">
                {session ? (
                    <div>
                        <img
                            src={
                                user?.imglink
                                    ? `${user?.imglink}?${new Date().getTime()}`
                                    : require("../../assets/shapes/pfp.svg").default
                            }
                            alt="pfp"
                            className="!w-[3vw] !h-[3vw] !rounded-full cursor-pointer"
                            onClick={(e) => {
                                setAnchorEl(e.currentTarget);
                            }}
                        />
                        <Popover
                            className="rounded-2xl"
                            id={id}
                            open={openpopover}
                            anchorEl={anchorEl}
                            onClose={() => setAnchorEl(null)}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            classes={{ paper: "popoverPaper" }}
                        >
                            <div className="p-[3vh] text-md flex flex-col items-start gap-[1vh]">
                                <div
                                    className="flex flex-row justify-center items-center gap-[0.75vw] cursor-pointer"
                                    onClick={() => {
                                        navigate("/creatorsettings");
                                        setAnchorEl(null);
                                    }}
                                >
                                    <img
                                        src={require("../../assets/shapes/settings.svg").default}
                                        alt="settings"
                                        className="!w-[1vw]"
                                    />
                                    <p>Settings</p>
                                </div>
                                <div className="flex flex-row justify-center items-center gap-[0.75vw] cursor-pointer">
                                    <img
                                        src={require("../../assets/shapes/helpandsupport.svg").default}
                                        alt="help"
                                        className="!w-[1vw]"
                                    />
                                    <p>Help and Support</p>
                                </div>
                                <div
                                    className="flex flex-row justify-center items-center gap-[0.75vw] cursor-pointer"
                                    onClick={() => logout()}
                                >
                                    <img
                                        src={require("../../assets/shapes/logout.svg").default}
                                        alt="logout"
                                        className="!w-[1.25vw]"
                                    />
                                    <p>Log Out</p>
                                </div>
                            </div>
                        </Popover>
                    </div>
                ) : (
                    <Link to="/signin" className="!px-[2vw] !py-[0.5vh]">
                        Log In
                    </Link>
                )}
            </div>
        </div>
    );
}
