import { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import "../assets/css/creatorsettings.css";
import { CountryDropdown } from "react-country-region-selector";
import UploadImagePopup from "../components/global/UploadImagePopup.jsx";
import { AuthContext } from "../App.js";
import TiktokIcon from "../assets/shapes/TikTokIcon.jsx";
import InstagramIcon from "../assets/shapes/InstagramIcon.jsx";
import YouTubeIcon from "../assets/shapes/YoutubeIcon.jsx";
import { supabase } from "../supabase.js";
import { checkMetadata, toastError, toastPromise, toastSuccess } from "../GlobalFunctions.ts";
import PuffLoader from "react-spinners/PuffLoader.js";
import { FaFacebook } from "react-icons/fa";
import Popup from "reactjs-popup";

export default function CreatorSettings() {
    const location = useLocation();
    const refuuid = location.state?.refuuid;
    const { user, setUser, logout } = useContext(AuthContext);

    // We will also append a URL fragment (#) with the user's short-lived User access token,
    // some metadata about the token, and the user's long-lived access token. Capture the long-lived access token.

    // capture these values from the URL fragment
    const fragment = location.hash.substring(1);
    const params = new URLSearchParams(fragment);
    // console.log("passed params:", params);

    const [country, setCountry] = useState("");
    const [bio, setBio] = useState("");
    const [tthandle, setTthandle] = useState("");
    const [ytchannel, setYtchannel] = useState("");
    const [media, setMedia] = useState([]);
    const [open, setOpen] = useState(false);
    const [pronouns, setPronouns] = useState("");
    const [currentcategory, setCurrentCategory] = useState("");
    const [categories, setCategories] = useState([]);
    const [ttrate, setTtrate] = useState("");
    const [igprate, setIgprate] = useState("");
    const [igsrate, setIgsrate] = useState("");
    const [cprate, setCprate] = useState("");
    const [firstbrand, setFirstBrand] = useState("");
    const [fblink, setFblink] = useState("");
    const [secondbrand, setSecondBrand] = useState("");
    const [sblink, setSblink] = useState("");
    const [thirdbrand, setThirdBrand] = useState("");
    const [tblink, setTblink] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [deletingaccount, setDeletingAccount] = useState(false);
    const [confirmdelete, setConfirmDelete] = useState("");
    const [instagramAccounts, setInstagramAccounts] = useState([]);
    const [selectedIGAccount, setSelectedIGAccount] = useState(null);

    useEffect(() => {
        if (!user) return;
        if (user.fb_access_token) {
            (async () => {
                try {
                    const fb_accounts = await fetch(
                        `https://graph.facebook.com/v20.0/me/accounts?fields=id,name,instagram_business_account,access_token&access_token=${user.fb_access_token}`
                    );
                    const fb_accounts_json = await fb_accounts.json();
                    let ig_accounts = fb_accounts_json.data
                        .filter((page) => page.instagram_business_account)
                        .map((page) => ({ id: page.instagram_business_account.id, name: page.name }));
                    // console.log("fb_accounts_json:", fb_accounts_json);
                    // console.log("ig_accounts:", ig_accounts);
                    let ig_accounts_data = await Promise.all(
                        ig_accounts.map(async (account) => {
                            const ig_account = await fetch(
                                `https://graph.facebook.com/v20.0/${account.id}?fields=username,profile_picture_url&access_token=${user.fb_access_token}`
                            );
                            return await ig_account.json();
                        })
                    );
                    // console.log("ig_accounts_data:", ig_accounts_data);
                    setInstagramAccounts(ig_accounts_data);
                } catch (error) {
                    console.error("Error fetching Instagram accounts:", error);
                }
            })();
            setSelectedIGAccount(user.ig_data);
        }
        const link = window.location.href;
        const id = link.split("#")[1];
        if (id) {
            document.getElementById(id)?.scrollIntoView({ behavior: "smooth", block: "center" });
        }
        setCountry(user.country || "");
        setBio(user.bio || "");
        setTthandle(user.tthandle || "");
        setYtchannel(user.ythandle || "");
        setPronouns(user.pronouns || "");
        setCategories(user.categories || []);
        setTtrate(user.ttrate || "");
        setIgprate(user.igprate || "");
        setIgsrate(user.igsrate || "");
        setCprate(user.cprate || "");
        setFirstBrand(user.firstbrand || "");
        setFblink(user.fblink || "");
        setSecondBrand(user.secondbrand || "");
        setSblink(user.sblink || "");
        setThirdBrand(user.thirdbrand || "");
        setTblink(user.tblink || "");
        setIsLoading(false);
    }, [user]);

    // useEffect(() => {
    //     if (selectedIGAccount) {
    //         console.log("selectedIGAccount:", selectedIGAccount);
    //     }
    // }, [selectedIGAccount]);

    if (isLoading) {
        return <PuffLoader color={"#5D87FF"} loading={true} size={150} id="loader" />;
    }

    return (
        <div className="absolute w-full">
            <div id="firstpagea" className="relative px-[3vw] !flex-row justify-between items-center w-full">
                <div id="imgcontainer" className="rounded-2xl relative w-1/2 flex items-center justify-center">
                    <img
                        src={require("../assets/images/creatorsettingsimage.jpeg")}
                        id="csimage"
                        className="h-[70vh] rounded-[50px] max-w-fit relative"
                        alt="creatorsettings"
                    />
                </div>
                <div className=" w-1/2">
                    <h1 id="createmediakit">Create your Media Kit</h1>
                    <p className="text-[#E91858] max-w-[60%]">
                        This is the first step to joining the ABI community - Highlight your unique value and start
                        connecting with brands! Join the Cause!
                    </p>
                    <button
                        className="bg-white font-bold p-[1vw] w-[30%] text-base rounded-full min-w-fit mt-[10vh] text-[#A9DCD4]"
                        onClick={() =>
                            document
                                .getElementById("secondpagecs")
                                .scrollIntoView({ behavior: "smooth", block: "start" })
                        }
                    >
                        GET STARTED
                    </button>
                </div>
            </div>
            <div
                id="secondpagecs"
                className="flex flex-col items-center justify-start w-full min-h-screen py-[5vh] px-[15vw] gap-[10vh]"
            >
                <div id="imgswitcher" className="relative">
                    <img
                        src={
                            user?.imglink
                                ? `${user?.imglink}?${new Date().getTime()}`
                                : require("../assets/shapes/blankimg.svg").default
                        }
                        alt="blankimg"
                        id="blankimgswitch"
                        className="w-[25vh] h-[25vh]"
                        style={{
                            padding: user?.imglink ? "0px" : "40px",
                            overflow: user?.imglink ? "hidden" : "visible",
                        }}
                    />
                    <button
                        className="bg-[#0A2A3E] absolute flex items-center justify-center p-[2vh] rounded-full bottom-0 right-0"
                        onClick={() => setOpen(true)}
                    >
                        <img src={require("../assets/shapes/editpfp.svg").default} alt="editpfp" />
                    </button>
                </div>
                <div
                    id="qgroup1"
                    className="flex flex-col items-center justify-center w-full customshadow py-[5vh] px-[10vw] rounded-2xl"
                >
                    <h1 className="mb-[.5vh] flex items-start justify-between w-full">Country</h1>
                    <CountryDropdown
                        id="countrydropdown"
                        classes="mb-[2vh]"
                        value={country}
                        onChange={(val) => setCountry(val)}
                        priorityOptions={["US", "CA", "GB"]}
                    />
                    {/* Bio Section */}
                    <h1 className="mb-[.5vh] flex items-start justify-between w-full">Bio</h1>
                    <textarea
                        id="biotextarea"
                        placeholder="Tell us about yourself!"
                        value={bio}
                        onChange={(e) => setBio(e.target.value)}
                        className="mb-[2vh]"
                    />
                    {/* Pronoun Section */}
                    <h1 className="mb-[.5vh] flex items-start justify-between w-full">Pronouns</h1>
                    <select
                        className="w-full customarea mb-[2vh]"
                        value={pronouns}
                        onChange={(e) => setPronouns(e.target.value)}
                    >
                        <option value="" disabled>
                            -Select an item-
                        </option>
                        <option value="She/Her">She/Her</option>
                        <option value="He/Him">He/Him</option>
                        <option value="They/Them">They/Them</option>
                        <option value="Other">Other</option>
                        <option value="NoShare">Prefer not to share</option>
                    </select>
                    {/* Categories Section */}
                    <h1 className="mb-[.5vh] flex items-start justify-between w-full">Categories</h1>
                    <div className="flex flex-row items-center justify-between w-full gap-[0.5vw] mb-[2vh]">
                        <select
                            className="w-3/4 customarea"
                            value={currentcategory}
                            defaultValue=""
                            onChange={(e) => setCurrentCategory(e.target.value)}
                        >
                            <option value="" disabled>
                                -Select an item-
                            </option>
                            <option value="Art and photography">Art and photography</option>
                            <option value="Beauty and makeup">Beauty and makeup</option>
                            <option value="Business">Business</option>
                            <option value="Diversity and inclusion">Diversity and inclusion</option>
                            <option value="Politics">Politics</option>
                            <option value="Fashion">Fashion</option>
                            <option value="Finance">Finance</option>
                            <option value="Food and beverage">Food and beverage</option>
                            <option value="Health and wellness">Health and wellness</option>
                            <option value="Home and gardening">Home and gardening</option>
                            <option value="Outdoor and nature">Outdoor and nature</option>
                            <option value="Parenting and family">Parenting and family</option>
                            <option value="Pets">Pets</option>
                            <option value="Sports and fitness">Sports and fitness</option>
                            <option value="Technology">Technology</option>
                            <option value="Travel">Travel</option>
                            <option value="Climate & Sustainability">Climate & Sustainability</option>
                            <option value="Lifestyle">Lifestyle</option>
                        </select>

                        <button
                            className="bg-[#0A2A3E] rounded-lg p-[0.25rem] w-1/4 !text-white !font-semibold"
                            onClick={() => {
                                if (currentcategory !== "" && !categories.includes(currentcategory)) {
                                    setCategories([...categories, currentcategory]);
                                    setCurrentCategory("");
                                }
                            }}
                        >
                            ADD
                        </button>
                    </div>
                    <div className="flex flex-row items-center justify-start w-full gap-[1vw] flex-wrap">
                        {categories.map((category, index) => (
                            <div
                                key={index}
                                className="bg-[#0A2A3E] text-white flex flex-row items-center p-[1vh] px-[1vw]  rounded-full"
                            >
                                {category}
                                <button
                                    className="delete text-lg"
                                    onClick={() => {
                                        const newCategories = [
                                            ...categories.slice(0, index),
                                            ...categories.slice(index + 1),
                                        ];
                                        setCategories(newCategories);
                                    }}
                                >
                                    <p className="ml-[0.75vh] mr-[0.75vh]">x</p>
                                </button>
                            </div>
                        ))}
                    </div>
                    <button
                        id="submitprofile"
                        type="button"
                        className="actionbtn rounded-3xl !text-white !font-semibold w-2/6 !bg-[#0A2A3E]"
                        onClick={async () => {
                            toastPromise(
                                new Promise(async (resolve, reject) => {
                                    const { error } = await supabase
                                        .from("users")
                                        .update({
                                            country,
                                            bio,
                                            pronouns,
                                            categories,
                                        })
                                        .eq("email", user?.email);
                                    if (error) {
                                        console.error("Error updating user profile:", error);
                                        reject(error.message);
                                    } else {
                                        resolve("Profile updated successfully!");
                                    }
                                }),
                                "Updating Profile..."
                            );
                        }}
                    >
                        SUBMIT
                    </button>
                </div>
                <div
                    id="qgroup2"
                    className="flex flex-col items-center justify-center w-full customshadow py-[5vh] px-[10vw] rounded-2xl"
                >
                    <div className="flex !flex-col !items-start !justify-between w-full !mb-[3vh]">
                        <div className="flex flex-row items-center justify-between gap-[1vw] self-start">
                            <InstagramIcon outercolor={"#E91858"} innercolor={"#FFFFFF"} />
                            <p>Instagram</p>
                        </div>
                        {user.fb_picture ? (
                            <div className="flex flex-col items-start justify-between gap-[2vh]">
                                <div className="!flex-row items-center gap-[1vw]">
                                    <div className="flex flex-row items-center bg-[#3B5998] p-[1vh] rounded-lg gap-[1vw] text-white">
                                        <FaFacebook color="white" />
                                        <p>Signed in as {user.fb_name}</p>
                                        <img
                                            src={user.fb_picture}
                                            alt="fbpic"
                                            className="w-[4vh] h-[4vh] rounded-full"
                                        />
                                    </div>
                                    <button
                                        className="text-[#E91858]"
                                        id="unlinkfb"
                                        onClick={async () => {
                                            // disconnect identity, and wipe ig_data
                                            const {
                                                data: { identities },
                                            } = await supabase.auth.getUserIdentities();
                                            // console.log("identities:", identities);
                                            const fbidentity = identities.find(
                                                (identity) => identity.provider === "facebook"
                                            );
                                            const { error } = await supabase.auth.unlinkIdentity(fbidentity);
                                            if (error) {
                                                console.error("Error unlinking Facebook account:", error);
                                                toastError("Error unlinking Facebook account. Please try again later.");
                                                return;
                                            }
                                            const { error: updateerror } = await supabase
                                                .from("users")
                                                .update({
                                                    ig_data: null,
                                                })
                                                .eq("email", user.email);
                                            if (updateerror) {
                                                console.error("Error updating user profile:", updateerror);
                                                toastError("Error unlinking Facebook account. Please try again later.");
                                                return;
                                            }
                                            const metadata = await checkMetadata();
                                            // console.log("metadata:", metadata);
                                            setUser((prev) => ({
                                                ...prev,
                                                ...metadata,
                                            }));
                                            setSelectedIGAccount(null);
                                            toastSuccess("Facebook account unlinked successfully!");
                                        }}
                                    >
                                        Unlink Facebook
                                    </button>
                                </div>
                                {/* display all the connected instagram accounts, and have a select next to each, allowing for a single insta
                                    user to be selected
                                */}
                                <div className="h-[1px] w-full bg-[#aab2b5]"></div>
                                <p className="mb-[1vh]">Which Instagram account would you like to display to brands?</p>
                                <div className="ig_account_grid">
                                    {instagramAccounts.map((account, index) => (
                                        <div
                                            key={index}
                                            className="ig_account flex flex-row items-center p-[1vh] gap-[1vw] bg-[#E91858] rounded-lg text-white"
                                        >
                                            <img
                                                src={account.profile_picture_url}
                                                alt="igpic"
                                                className="w-[4vh] h-[4vh] rounded-full"
                                            />
                                            <p>{account.username}</p>
                                            <input
                                                type="checkbox"
                                                className="checkbox-round ml-auto mr-[1vh]"
                                                checked={selectedIGAccount?.id === account.id}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setSelectedIGAccount({
                                                            ...account,
                                                            access_token: user.fb_access_token,
                                                        });
                                                    } else {
                                                        setSelectedIGAccount(null);
                                                    }
                                                }}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            // follower_count -> period: day
                            // likes -> period: day
                            // comments -> period: day
                            // impressions -> period: day
                            // shares -> period: day
                            // reach -> period: day_28
                            // engaged_audience_demographics -> period: lifetime
                            // reached_audience_demographics -> period: lifetime
                            // build a query to put into facebook graph api explorer

                            // get follower count with GET https://graph.facebook.com/v20.0/{ig-user-id}?fields=followers_count&access_token={access-token}

                            // https://graph.facebook.com/v20.0/{ig-user-id}/insights?
                            // metric=impressions,reach
                            //      &period=days_28

                            // &metric=likes,comments,shares
                            //      &period=day
                            //      &metric_type=total_value

                            // get age, country, and gender from DIFFERENT calls
                            // &metric=engaged_audience_demographics,reached_audience_demographics
                            //      &period=lifetime
                            //      &timeframe=this_month
                            //      &breakdown=age,city,country,gender
                            //      &metric_type=total_value

                            <button
                                className="flex flex-row items-center bg-[#3B5998] p-[1vh] rounded-lg gap-[1vw] text-white"
                                onClick={async () => {
                                    const {
                                        data: { identities },
                                    } = await supabase.auth.getUserIdentities();
                                    // console.log("identities:", identities);
                                    if (identities.find((identity) => identity.provider === "facebook")) {
                                        const { error: fberror } = await supabase.auth.unlinkIdentity(
                                            identities.find((identity) => identity.provider === "facebook")
                                        );
                                        if (fberror) {
                                            console.error("Error unlinking Facebook account:", fberror);
                                            toastError("Error linking Facebook account. Please try again later.");
                                            return;
                                        }
                                    }
                                    const { data, error } = await supabase.auth.linkIdentity({
                                        provider: "facebook",
                                        options: {
                                            // redirectTo: "http://localhost:3000",
                                            redirectTo:
                                                process.env.NODE_ENV === "development"
                                                    ? "http://localhost:3000/creatorsettings"
                                                    : "https://abetterinfluence.com/creatorsettings",
                                            scopes: "instagram_basic,instagram_content_publish,instagram_manage_insights,pages_show_list,pages_read_engagement",
                                            // instagram_manage_comments
                                        },
                                    });
                                    if (error) {
                                        console.error("Error linking Facebook account:", error);
                                        toastError("Error linking Facebook account. Please try again later.");
                                    } else {
                                        console.log("fb data:", data);
                                    }
                                }}
                            >
                                <FaFacebook color="white" />
                                Login with Facebook
                            </button>
                        )}
                    </div>
                    <div className="flex items-start justify-between w-full">
                        <TiktokIcon outercolor={"#E91858"} innercolor={"#FFFFFF"} />
                        <p>Tiktok</p>
                    </div>
                    <input placeholder="Tiktok Handle" value={tthandle} onChange={(e) => setTthandle(e.target.value)} />
                    <button
                        className="actionbtn rounded-3xl !text-white !font-semibold w-2/6 !bg-[#0A2A3E]"
                        onClick={async () => {
                            toastPromise(
                                new Promise(async (resolve, reject) => {
                                    const { error } = await supabase
                                        .from("users")
                                        .update({
                                            tthandle,
                                            ig_data: selectedIGAccount,
                                        })
                                        .eq("email", user?.email);
                                    if (error) {
                                        console.error("Error updating user profile:", error);
                                        reject(error.message);
                                        return;
                                    }
                                    if (tthandle !== "") {
                                        fetch(
                                            (process.env.NODE_ENV === "development"
                                                ? "http://127.0.0.1:8080"
                                                : "https://abi-backend-b4g3na7wyq-uk.a.run.app/") +
                                                "/?username=" +
                                                tthandle
                                        )
                                            .then((response) => response.json())
                                            .then(async (data) => {
                                                // console.log("data:", data);
                                                const { data: priordata, error: priorerror } = await supabase
                                                    .from("users")
                                                    .select("analytics")
                                                    .eq("handle", user.handle)
                                                    .single();
                                                // console.log("priordata:", priordata);
                                                if (priorerror) {
                                                    console.error("Error fetching creator data:", priorerror);
                                                    toastError("Error fetching creator data. Please try again later.");
                                                    reject("Error fetching creator data. Please try again later.");
                                                }
                                                const { error: updateerror } = await supabase
                                                    .from("users")
                                                    .update({
                                                        analytics: {
                                                            ...priordata.analytics,
                                                            ...data,
                                                            lastrefreshed: new Date(),
                                                        },
                                                    })
                                                    .eq("handle", user.handle);
                                                if (updateerror) {
                                                    console.error("Error updating creator data:", updateerror);
                                                    toastError("Error updating creator data. Please try again later.");
                                                    reject("Error updating creator data. Please try again later.");
                                                }
                                                resolve("Profile updated successfully!");
                                            })
                                            .catch((error) => {
                                                console.error("Error fetching creator data:", error);
                                                toastError("Error fetching creator data. Please try again later.");
                                                reject("Error fetching creator data. Please try again later.");
                                            });
                                    } else {
                                        resolve("Profile updated successfully!");
                                    }
                                }),
                                "Updating Profile...(This may take a minute or two)"
                            );
                        }}
                    >
                        Save Changes
                    </button>
                    {/* <div className="flex items-start justify-between w-full">
                        <YouTubeIcon outercolor={"#E91858"} innercolor={"#FFFFFF"} />
                        <p>Youtube</p>
                    </div>
                    <input
                        placeholder="Youtube Channel"
                        value={ytchannel}
                        onChange={(e) => setYtchannel(e.target.value)}
                    /> */}
                </div>

                {/*Rates Section*/}
                <div
                    id="qgroup3"
                    className="flex flex-col items-center justify-center w-full customshadow py-[5vh] px-[10vw] rounded-2xl"
                >
                    <h1 className="mb-[1vh] flex flex-col items-center justify-between">Rates</h1>

                    <div className="flex items-start justify-between w-full">
                        <TiktokIcon outercolor={"#E91858"} innercolor={"#FFFFFF"} />
                        <p>TikTok</p>
                    </div>
                    <div className="ratesbox">
                        <ul>
                            <li>
                                <div className="flex flex-row items-baseline">
                                    <p className="ml-[1vh] text-3xl font-semibold">$</p>
                                    <textarea
                                        id="ratesinput"
                                        value={ttrate}
                                        placeholder="0"
                                        rows="1"
                                        onChange={(e) => setTtrate(e.target.value)}
                                    />
                                </div>
                                <p className="ml-[2vh] text-gray-400">TikTok Post</p>
                            </li>
                        </ul>
                    </div>
                    {/* <div className="flex items-start justify-between w-full">
                        <InstagramIcon outercolor={"#E91858"} innercolor={"#FFFFFF"} />
                        <p>Instagram</p>
                    </div>
                    <div className="ratesbox">
                        <ul>
                            <li className="mt-[2vh] mb-[1vh]">
                                <div className="flex flex-row items-baseline">
                                    <p className="ml-[1vh] text-3xl font-semibold">$</p>
                                    <textarea
                                        id="ratesinput"
                                        value={igprate}
                                        placeholder="0"
                                        rows="1"
                                        onChange={(e) => setIgprate(e.target.value)}
                                    />
                                </div>
                                <p className="ml-[2vh] text-gray-400">Instagram Post</p>
                            </li>
                            <li className="mt-[2vh] mb-[2vh]">
                                <div className="flex flex-row items-baseline">
                                    <p className="ml-[1vh] text-3xl font-semibold">$</p>
                                    <textarea
                                        id="ratesinput"
                                        value={igsrate}
                                        placeholder="0"
                                        rows="1"
                                        onChange={(e) => setIgsrate(e.target.value)}
                                    />
                                </div>
                                <p className="ml-[2vh] text-gray-400">Instagram Story</p>
                            </li>
                        </ul>
                    </div> */}
                    <div className="flex items-start justify-between w-full">
                        <YouTubeIcon outercolor={"#E91858"} innercolor={"#FFFFFF"} />
                        <p>Crossposting</p>
                    </div>
                    <div className="ratesbox">
                        <ul>
                            <li>
                                <div className="flex flex-row items-baseline">
                                    <p className="ml-[1vh] text-3xl font-semibold">$</p>
                                    <textarea
                                        id="ratesinput"
                                        value={cprate}
                                        placeholder="0"
                                        rows="1"
                                        onChange={(e) => setCprate(e.target.value)}
                                    />
                                </div>
                                <p className="ml-[2vh] text-gray-400">Crossposting Content</p>
                            </li>
                        </ul>
                    </div>
                    <button
                        id="submitprofile"
                        type="button"
                        className="actionbtn rounded-3xl !text-white !font-semibold w-2/6 !bg-[#0A2A3E]"
                        onClick={async () => {
                            toastPromise(
                                new Promise(async (resolve, reject) => {
                                    const { error } = await supabase
                                        .from("users")
                                        .update({
                                            ttrate,
                                            igprate,
                                            igsrate,
                                            cprate,
                                        })
                                        .eq("email", user?.email);
                                    if (error) {
                                        console.error("Error updating user profile:", error);
                                        reject(error.message);
                                    } else {
                                        resolve("Profile updated successfully!");
                                    }
                                }),
                                "Updating Profile..."
                            );
                        }}
                    >
                        SUBMIT
                    </button>
                </div>

                <div
                    id="qgroup4"
                    className="flex flex-col items-center justify-center w-full customshadow py-[5vh] px-[10vw] rounded-2xl"
                >
                    <h1 className="mb-[1vh] flex items-center justify-between">Add Collaborations</h1>

                    <h1 className="mb-[.5vh] flex items-start justify-between w-full">Collaboration #1</h1>
                    <div className="flex flex-row items-center justify-between w-full gap-[0.5vw] mb-[2vh]">
                        <input
                            className="w-1/2 customarea"
                            value={firstbrand}
                            placeholder="Insert brand name"
                            onChange={(e) => setFirstBrand(e.target.value)}
                        />
                        <input
                            className="w-1/2 customarea"
                            value={fblink}
                            placeholder="Insert link to your content"
                            onChange={(e) => setFblink(e.target.value)}
                        />
                    </div>
                    <h1 className="mb-[.5vh] flex items-start justify-between w-full">Collaboration #2</h1>
                    <div className="flex flex-row items-center justify-between w-full gap-[0.5vw] mb-[2vh]">
                        <input
                            className="w-1/2 customarea"
                            value={secondbrand}
                            placeholder="Insert brand name"
                            onChange={(e) => setSecondBrand(e.target.value)}
                        />
                        <input
                            className="w-1/2 customarea"
                            value={sblink}
                            placeholder="Insert link to your content"
                            onChange={(e) => setSblink(e.target.value)}
                        />
                    </div>
                    <h1 className="mb-[.5vh] flex items-start justify-between w-full">Collaboration #3</h1>
                    <div className="flex flex-row items-center justify-between w-full gap-[0.5vw] mb-[2vh]">
                        <input
                            className="w-1/2 customarea"
                            value={thirdbrand}
                            placeholder="Insert brand name"
                            onChange={(e) => setThirdBrand(e.target.value)}
                        />
                        <input
                            className="w-1/2 customarea"
                            value={tblink}
                            placeholder="Insert link to your content"
                            onChange={(e) => setTblink(e.target.value)}
                        />
                    </div>
                    <button
                        id="submitprofile"
                        type="button"
                        className="actionbtn rounded-3xl !text-white !font-semibold w-2/6 !bg-[#0A2A3E]"
                        onClick={async () => {
                            toastPromise(
                                new Promise(async (resolve, reject) => {
                                    const { error } = await supabase
                                        .from("users")
                                        .update({
                                            firstbrand,
                                            fblink,
                                            secondbrand,
                                            sblink,
                                            thirdbrand,
                                            tblink,
                                        })
                                        .eq("email", user?.email);
                                    if (error) {
                                        console.error("Error updating user profile:", error);
                                        reject(error.message);
                                    } else {
                                        resolve("Profile updated successfully!");
                                    }
                                }),
                                "Updating Profile..."
                            );
                        }}
                    >
                        SUBMIT
                    </button>
                </div>

                <div
                    id="accmedia"
                    className="flex flex-col items-center justify-center w-full customshadow py-[5vh] px-[10vw] rounded-2xl"
                >
                    <p>
                        Media{" "}
                        <span className="bg-[#0A2A3E] text-white rounded-full p-[1vh]">Generate your best content</span>
                    </p>
                    <p>Upload your photos and videos to create a mood board. Showcase your unique style.</p>
                </div>
                <Link
                    className="bg-[#0A2A3E] text-white rounded-full p-[1vh] px-[2vw]"
                    to={"/mediakit/" + user.handle}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    View your Media Kit
                </Link>
                <button
                    id="deleteaccount"
                    className="bg-[#E91858] hover:bg-red-700 text-white p-[1vh] px-[2vw] rounded-full"
                    onClick={() => setDeletingAccount(true)}
                >
                    Delete Account
                </button>
            </div>
            <Popup
                open={deletingaccount}
                closeOnDocumentClick
                onClose={() => setDeletingAccount(false)}
                className="flex flex-col items-center justify-center"
                overlayStyle={{ background: "rgba(0,0,0,0.5)" }}
            >
                <p className="mb-2 text-lg">Are you sure you want to delete your account?</p>
                <p className="mb-2 text-sm">
                    Type<span className="font-bold text-md">Delete Account</span>to confirm
                </p>
                <label htmlFor="confirmDelete" className="sr-only">
                    Confirm Delete
                </label>
                <input
                    alt="ConfirmDelete"
                    id="confirmDelete"
                    type="text"
                    className="border-2 border-gray-300 rounded-lg p-1"
                    onChange={(e) => setConfirmDelete(e.target.value)}
                />
                <button
                    id="deleteAccount"
                    onClick={async () => {
                        if (confirmdelete === "Delete Account") {
                            await logout(true);
                        } else {
                            toastError("Please type 'Delete Account' to confirm deletion.");
                        }
                    }}
                    className="bg-[#E91858] my-2 text-[1rem] rounded-lg shadow-2xl !px-[1vw] !py-1 text-white"
                >
                    Delete
                </button>
            </Popup>
            <UploadImagePopup open={open} setOpen={setOpen} />
        </div>
    );
}
