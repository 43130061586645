import { ReactComponent as LeftTree } from "../assets/shapes/lefttree.svg";
import { ReactComponent as RightTree } from "../assets/shapes/righttree.svg";
import { ReactComponent as BottomLeftTree } from "../assets/shapes/bottomlefttree.svg";
import { ReactComponent as BottomMidTree } from "../assets/shapes/bottommidtree.svg";
import { ReactComponent as BottomRightTree } from "../assets/shapes/bottomrighttree.svg";
import "../assets/css/home.css";
import { GlobalStyles } from "../GlobalStyles";
import { useContext, useEffect, useState } from "react";
import { toastError } from "../GlobalFunctions.ts";
import { supabase } from "../supabase";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import About from "../components/home/About";
import { AuthContext } from "../App";
import PuffLoader from "react-spinners/PuffLoader";
import { useMediaQuery } from "react-responsive";

export default function Home() {
    const navigate = useNavigate();
    const [firstname, setFirstName] = useState("");
    const [lastname, setLastName] = useState("");
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const location = useLocation();
    const refuuid = location.state?.refuuid;

    const [isLoading, setIsLoading] = useState(true);
    const [signButtonClicked, setSignButtonClicked] = useState(false);
    const atleast501 = useMediaQuery({ query: "(min-width: 501px)" });
    const atleast569 = useMediaQuery({ query: "(min-width: 569px)" });
    const atmost700 = useMediaQuery({ query: "(max-width: 700px)" });
    const atleast851 = useMediaQuery({ query: "(min-width: 851px)" });

    useEffect(() => {
        const loadImage = (image) => {
            return new Promise((resolve, reject) => {
                const loadImg = new Image();
                loadImg.src = image.src;
                loadImg.onload = () => resolve(image.src);
                loadImg.onerror = (err) => reject(err);
            });
        };
        loadImage({ src: require("../assets/images/homeimage.webp") })
            .then(() => setIsLoading(false))
            .catch((err) => console.log("Failed to load images:", err));
    }, []);

    useEffect(() => {
        // if refuuid exists, smoothly scroll to #secondpage
        if (!isLoading) {
            console.log("refuuid:", refuuid);
            if (refuuid) {
                document.getElementById("secondpage").scrollIntoView({ behavior: "smooth", block: "center" });
            }
        }
    }, [isLoading, refuuid]);

    if (isLoading) {
        return <PuffLoader color={"#5D87FF"} loading={true} size={150} id="loader" />;
    }

    return (
        <div className="absolute w-full overflow-x-hidden">
            <div className="relative firstpage">
                <div className="flex flex-row justify-between items-center">
                    <LeftTree />
                    <RightTree className="" />
                </div>
                <div className="!flex-row justify-between">
                    <BottomLeftTree />
                    <BottomMidTree />
                    <BottomRightTree />
                </div>
                <div id="middlefirst" className="!flex-row justify-between absolute items-center w-[35%]">
                    <div
                        id="homeimagediv"
                        style={{ backgroundColor: GlobalStyles.blue }}
                        className="p-[1.5vw] rounded-2xl relative"
                    >
                        <div
                            id="phonetop"
                            className="absolute w-3/5 h-[2vh]"
                            style={{
                                borderBottomLeftRadius: 14,
                                borderBottomRightRadius: 14,
                                backgroundColor: GlobalStyles.blue,
                            }}
                        ></div>
                        <img
                            id="homeimage"
                            src={require("../assets/images/homeimage.webp")}
                            className="rounded-2xl max-w-fit object-cover max-h-[60vh]"
                            alt="home"
                        />
                    </div>
                    <div id="firstpagetext" className="gap-[3vh]">
                        <p id="firstpagetitle" className=" font-bold italic text-5xl">
                            A Better Impact™ Platform
                        </p>
                        <p id="firstpagetitle" style={{ fontFamily: "Ivy Mode" }} className=" font-bold text-5xl">
                        Empowering Change One Post at a Time
                        </p>
                        <p className=" font-medium text-xl">
                            Make <span className="font-bold">content</span> while making a difference. Sign the pledge
                            today!
                        </p>
                        <button
                            className="bg-white font-bold p-[1vw] w-[30%] text-base rounded-full min-w-fit"
                            onClick={() =>
                                document
                                    .getElementById("secondpage")
                                    .scrollIntoView({ behavior: "smooth", block: "center" })
                            }
                        >
                            Book a call with a representative
                        </button>
                    </div>
                </div>
            </div>
            <div
                id="secondpage"
                className="bg-[#FAAC73] flex flex-row items-center justify-center relative overflow-hidden"
            >
                <div id="leftsecondpage">
                    <p className=" font-semibold text-4xl">Pledge to Keep Fossil Fuels Off of Your Social Media</p>
                    <p>Sign the Pledge to Join the Creator Community of the Future. 🌎</p>
                    <input type="text" placeholder="First Name" onChange={(e) => setFirstName(e.target.value)} />
                    <input type="text" placeholder="Last Name" onChange={(e) => setLastName(e.target.value)} />
                    <input
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        // 500-569 show "Handle", 570-851 show "IG / TT Username" else show "Instagram/Tiktok Username"
                        // placeholder="Instagram/Tiktok Username"
                        placeholder={
                            atleast501 && !atleast569
                                ? "Handle"
                                : atleast569 && !atleast851
                                ? "IG / TT Username"
                                : "Instagram/Tiktok Username"
                        }
                        type="text"
                        autoCapitalize="none"
                        autoCorrect="false"
                    />
                    <input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                        type="email-address"
                        autoCapitalize="none"
                        autoCorrect="false"
                    />
                    <input
                        id="signupbutton"
                        type="button"
                        className="actionbtn rounded-3xl !text-white !font-semibold !px-[2vw] !py-[0.5vh] mb-[5vh]"
                        disabled={signButtonClicked}
                        onClick={async () => {
                            setSignButtonClicked(true);
                            if (firstname === "" || lastname === "" || username === "" || email === "") {
                                toastError("Please fill out all fields!");
                            } else {
                                // check if row with email exists, and if it does, alert and saw that we're still reviewing their application
                                const { data: emailData, error: emailError } = await supabase
                                    .from("users")
                                    .select("*")
                                    .eq("email", email)
                                    .single();
                                if (emailError) {
                                    console.log("no email found:", emailError);
                                    const { data, error } = await supabase
                                        .from("users")
                                        .insert({
                                            first_name: firstname,
                                            last_name: lastname,
                                            handle: username,
                                            email,
                                        })
                                        .select("uuid")
                                        .single();
                                    if (error) {
                                        console.error(error);
                                        toastError(error.message);
                                    } else {
                                        // if refuuid exists, add to referrals
                                        if (refuuid) {
                                            // get array currently in referrals
                                            const { data: refData, error: refError } = await supabase
                                                .from("users")
                                                .select("referrals")
                                                .eq("uuid", refuuid)
                                                .single();

                                            if (refError) {
                                                console.error(refError);
                                                toastError(refError.message);
                                            } else {
                                                // add new user to referrals
                                                const { error: updateError } = await supabase
                                                    .from("users")
                                                    .update({ referrals: [...(refData.referrals || []), data.uuid] })
                                                    .eq("uuid", refuuid);
                                                if (updateError) {
                                                    console.error(updateError);
                                                    toastError(updateError.message);
                                                }
                                            }
                                        }
                                        navigate("/postsignup", { state: { uuid: data.uuid } });
                                    }
                                } else {
                                    console.log("email found:", emailData);
                                    if (emailData.confirmed) {
                                        console.log("email already confirmed");
                                        toastError("There was an account found with that email. Please sign in.");
                                    } else {
                                        toastError("Please be patient. We're still reviewing your application.");
                                    }
                                }
                            }
                            setSignButtonClicked(false);
                        }}
                        value="SIGN THE PLEDGE"
                    />
                </div>
                <img id="pledgeimg" src={require("../assets/images/pledgepic.png")} alt="pledge" loading="lazy" />
            </div>
            <div
                id="thirdpage"
                className="text-center h-[100vh] bg-[#FAAC73] flex flex-col items-center justify-center z-50 relative text-white gap-[1vh]"
            >
                <img
                    src={require("../assets/images/impactpic.jpeg")}
                    alt="impact"
                    style={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        filter: "brightness(0.75) hue-rotate(180deg)",
                    }}
                    loading="lazy"
                />
                <p id="impacttext" className="z-10 font-semibold text-4xl">
                    Our total impact:
                </p>
                <div
                    id="impactnum"
                    className="z-10 !flex-row gap-[3vh] rounded-2xl max-w-full"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.25)" }}
                >
                    {"8693371".split("").map((num, i) => (
                        <p
                            className="font-bold text-[8vw] p-[1vw] rounded-lg"
                            style={{ border: "3px solid white" }}
                            key={i}
                        >
                            {num}
                        </p>
                    ))}
                </div>
                <p id="userreach" className="z-10 font-semibold text-4xl">
                    Combined Instagram and TikTok User Reach
                </p>
                <button
                    id="joinbutton"
                    className="bg-white font-bold p-[1vw] rounded-full z-10 text-black px-[2vw]"
                    onClick={() =>
                        document.getElementById("secondpage").scrollIntoView({ behavior: "smooth", block: "center" })
                    }
                >
                    JOIN US
                </button>
            </div>
            {/* <About /> */}
        </div>
    );
}
