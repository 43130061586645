import React from "react";
import { useLocation } from "react-router-dom";
// import { FaFacebook, FaGithub, FaInstagram, FaTwitter, FaTwitch } from "react-icons/fa";

const sections = [
    {
        title: "Solutions",
        items: ["Marketing", "Analytics", "Commerce", "Data"],
    },
    {
        title: "Support",
        items: ["Pricing", "Documentation", "Guides"],
    },
    {
        title: "Company",
        items: ["About", "Blog", "Jobs", "Press", "Partners"],
    },
    {
        title: "Legal",
        items: ["Privacy", "Terms"],
    },
];

const items = [
    // { name: "Facebook", icon: FaFacebook, link: "https://www.facebook.com/" },
    // { name: "Instagram", icon: FaInstagram, link: "https://www.instagram.com/" },
    // { name: "Twitter", icon: FaTwitter, link: "https://twitter.com/" },
    // { name: "Twitch", icon: FaTwitch, link: "https://www.twitch.tv/" },
    // { name: "Github", icon: FaGithub, link: "https://github.com/" },
];

const Footer = () => {
    const { pathname } = useLocation();
    // console.log(pathname);
    if (pathname === "/creatorsettings") {
        return null;
    }
    return (
        <div className="w-full bg-slate-900 text-gray-300 py-y px-2">
            <div className="max-w-[90vw] mx-auto grid grid-cols-2 md:grid-cols-6 border-b-2 border-gray-600 py-8">
                {sections.map((section, index) => (
                    <div key={index}>
                        <h6 className="font-bold uppercase pt-2">{section.title}</h6>
                        <ul>
                            {section.items.map((item, i) => (
                                <a href={"/" + item}>
                                    <li key={i} className="py-1 text-gray-500 hover:text-white">
                                        {item}
                                    </li>
                                </a>
                            ))}
                        </ul>
                    </div>
                ))}

                <div className="col-span-2 pt-8 md:pt-2">
                    <p className="font-bold uppercase">Subscribe for updates</p>
                    <p className="py-4">Learn more about our moves in the Gen Z Sustainable Marketing Space.</p>
                    <form className="flex flex-col sm:flex-row">
                        <input className="w-full p-2 mr-4 rounded-md mb-4" type="email" placeholder="Enter email.." />
                        <button className="p-2 mb-4">COMING SOON</button>
                    </form>
                </div>
            </div>

            <div className="flex flex-col max-w-[1240px] px-2 py-4 mx-auto justify-between sm:flex-row text-center text-gray-500">
                <p className="py-4">Copyright © 2023 Viviene New York - All rights reserved.</p>
                {/* <div className="flex justify-between sm:w-[300px] pt-4 text-2xl">
                    {items.map((x, index) => {
                        return <x.icon key={index} className="hover:text-white" />;
                    })}
                </div> */}
            </div>
        </div>
    );
};

export default Footer;
