import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../assets/css/mediakit.css";
import YouTubeIcon from "../assets/shapes/YoutubeIcon";
import TikTokIcon from "../assets/shapes/TikTokIcon";
import InstagramIcon from "../assets/shapes/InstagramIcon";
import { supabase } from "../supabase";
import PuffLoader from "react-spinners/PuffLoader";
import { Switch, SwitchLabel, SwitchRadio, SwitchSelection } from "../components/mediakit/Switch";
import { ReactComponent as RefreshIcon } from "../assets/shapes/refresh_icon.svg";
import { toastError, toastSuccess } from "../GlobalFunctions.ts";
import { CgPlayButton } from "react-icons/cg";
import { IoMdVolumeOff, IoMdVolumeHigh } from "react-icons/io";
import { FaThumbsUp, FaComment } from "react-icons/fa6";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function MediaKit() {
    const [creator, setCreator] = useState({});
    const { creatorhandle } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [selected, setSelected] = useState("TikTok");
    const [isPlaying, setIsPlaying] = useState([false, false, false]);
    const [isMuted, setIsMuted] = useState([true, true, true]);
    const [igloading, setIgLoading] = useState(true);

    useEffect(() => {
        // console.log("creatorhandle:", creatorhandle);
        // Fetch creator data here and update state
        // setCreator(fetchedData);
        (async () => {
            const { data: userdata, error } = await supabase
                .from("users")
                .select("*")
                .eq("handle", creatorhandle)
                .single();
            if (error) {
                console.error("Error fetching creator data:", error);
                setCreator(null);
                setIsLoading(false);
                return;
            } else {
                // console.log("userdata:", userdata);
                const ig_f_and_p = await fetch(
                    `https://graph.facebook.com/v20.0/${userdata.ig_data?.id}?fields=followers_count&access_token=${userdata.ig_data?.access_token}`
                );
                const ig_f_and_p_json = await ig_f_and_p.json();
                setCreator({ ...userdata, ig: { ...(userdata.ig || {}), followers: ig_f_and_p_json.followers_count } });
                setIsLoading(false);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            if (selected === "Instagram") {
                // load top three
                const ig_data = await fetch(
                    `https://graph.facebook.com/v20.0/${creator.ig_data?.id}/media?fields=caption,media_url,comments_count,like_count,thumbnail_url,media_type,insights.metric(impressions)&limit=3&access_token=${creator.ig_data?.access_token}`
                );
                const ig_json = await ig_data.json();
                // console.log("ig_json:", ig_json);
                // follower_count -> period: day
                // likes -> period: day
                // comments -> period: day
                // impressions -> period: day
                // shares -> period: day
                // reach -> period: day_28
                // engaged_audience_demographics -> period: lifetime
                // reached_audience_demographics -> period: lifetime
                // build a query to put into facebook graph api explorer

                // get follower count with GET https://graph.facebook.com/v20.0/{ig-user-id}?fields=followers_count&access_token={access-token}

                // https://graph.facebook.com/v20.0/{ig-user-id}/insights?
                // metric=impressions,reach
                //      &period=days_28

                // &metric=likes,comments,shares,accounts_engaged
                //      &period=day
                //      &metric_type=total_value

                // get age, country, and gender from DIFFERENT calls
                // &metric=engaged_audience_demographics,reached_audience_demographics
                //      &period=lifetime
                //      &timeframe=this_month
                //      &breakdown=age,city,country,gender
                //      &metric_type=total_value

                // const media_count = ig_f_and_p_json.media_count;
                // 2. get impressions and reach
                const impressions_and_reach = await fetch(
                    `https://graph.facebook.com/v20.0/${creator.ig_data?.id}/insights?metric=impressions,reach&period=days_28&access_token=${creator.ig_data?.access_token}`
                );
                const impressions_and_reach_json = await impressions_and_reach.json();
                // console.log("impressions_and_reach_json:", impressions_and_reach_json);
                const impressions = impressions_and_reach_json.data[0].values[1].value;
                const reach = impressions_and_reach_json.data[1].values[1].value;
                // 3. get likes, comments, shares, and accounts_engaged
                const likes_comments_shares = await fetch(
                    `https://graph.facebook.com/v20.0/${creator.ig_data?.id}/insights?metric=likes,comments,shares,accounts_engaged&period=day&metric_type=total_value&access_token=${creator.ig_data?.access_token}`
                );
                const likes_comments_shares_json = await likes_comments_shares.json();
                // console.log("likes_comments_shares_json:", likes_comments_shares_json);
                const likes = likes_comments_shares_json.data[0].total_value.value;
                const comments = likes_comments_shares_json.data[1].total_value.value;
                const shares = likes_comments_shares_json.data[2].total_value.value;
                const accounts_engaged = likes_comments_shares_json.data[3].total_value.value;
                // 4. get reached_audience_demographics
                const demographics_age = await fetch(
                    `https://graph.facebook.com/v20.0/${creator.ig_data?.id}/insights?metric=reached_audience_demographics&period=lifetime&timeframe=this_month&breakdown=age&metric_type=total_value&access_token=${creator.ig_data?.access_token}`
                );
                const demographics_age_json = await demographics_age.json();
                // console.log("demographics_json:", demographics_age_json);
                // if json is empty, set reached_audience_demographics to empty arrays
                // otherwise, set them to the values in the json
                let reached_audience_demographics_age =
                    demographics_age_json.data[0]?.total_value.breakdowns.results || [];

                const demographics_country = await fetch(
                    `https://graph.facebook.com/v20.0/${creator.ig_data?.id}/insights?metric=reached_audience_demographics&period=lifetime&timeframe=this_month&breakdown=country&metric_type=total_value&access_token=${creator.ig_data?.access_token}`
                );
                const demographics_country_json = await demographics_country.json();
                // console.log("demographics_json:", demographics_country_json);
                let reached_audience_demographics_country =
                    demographics_country_json.data[0]?.total_value.breakdowns.results || [];

                const demographics_gender = await fetch(
                    `https://graph.facebook.com/v20.0/${creator.ig_data?.id}/insights?metric=reached_audience_demographics&period=lifetime&timeframe=this_month&breakdown=gender&metric_type=total_value&access_token=${creator.ig_data?.access_token}`
                );
                const demographics_gender_json = await demographics_gender.json();
                // console.log("demographics_json:", demographics_gender_json);
                let reached_audience_demographics_gender =
                    demographics_gender_json.data[0]?.total_value.breakdowns.results || [];

                setCreator((prev) => ({
                    ...prev,
                    ig: {
                        ...(prev.ig || {}),
                        top_three_posts: ig_json.data,
                        // media_count,
                        impressions,
                        reach,
                        likes,
                        comments,
                        shares,
                        accounts_engaged,
                        reached_audience_demographics: {
                            age: reached_audience_demographics_age,
                            country: reached_audience_demographics_country,
                            gender: reached_audience_demographics_gender,
                        },
                    },
                }));
                // setIgLoading(false);
            }
        })();
    }, [selected]);

    useEffect(() => {
        if (!creator?.ig || (creator?.ig && creator?.ig.top_three_posts)) {
            setIgLoading(false);
        }
    }, [creator]);

    const handlePlayPause = (index) => {
        const videoElement = document.getElementById(`video-element-${index}`);
        const newIsPlaying = [...isPlaying];
        if (newIsPlaying[index]) {
            videoElement.pause();
        } else {
            videoElement.play();
        }
        newIsPlaying[index] = !newIsPlaying[index];
        setIsPlaying(newIsPlaying);
    };

    const handleMute = (index) => {
        const videoElement = document.getElementById(`video-element-${index}`);
        const newIsMuted = [...isMuted];
        if (newIsMuted[index]) {
            videoElement.muted = false;
        } else {
            videoElement.muted = true;
        }
        newIsMuted[index] = !newIsMuted[index];
        setIsMuted(newIsMuted);
    };

    if (isLoading) {
        return <PuffLoader color={"#5D87FF"} loading={true} size={150} id="loader" />;
    }

    return creator ? (
        <div className="media-kit text-[#0A2A3E] !pb-0">
            {/* Profile Section */}
            <div className="section profile items-center gap-[1vh]">
                <img src={creator.imglink} alt="creator" className="creator-img" />
                <h1 className="creator-name leading-[6vh]">{creator.first_name + " " + creator.last_name}</h1>
                <p className="creator-pronouns font-medium">{creator.pronouns}</p>
                <div className="creator-tags flex-row">
                    {creator.categories?.map((tag, index) => (
                        <p key={index} className="bg-[#0A2A3E] text-[#FFF5CF] p-[1vh] px-[1vw] rounded-full">
                            {tag}
                        </p>
                    ))}
                </div>
                <div className="creator-stats">
                    <div className="stat items-center">
                        <p>Total Audience</p>
                        <div className="stat-info flex-row items-center">
                            <img
                                src={require("../assets/shapes/audienceicon.svg").default}
                                alt="audience"
                                className="stat-icon"
                            />
                            <p className="font-semibold text-[2vw]">
                                {((creator.analytics.followers || 0) + (creator.ig?.followers || 0)).toLocaleString()}
                            </p>
                        </div>
                    </div>
                    {/* <div className="stat">
            <p>Largest Platform</p>
            <div className="stat-info">
              {creator.largestplatform === "YouTube" && <YoutubeIcon outercolor={"#FFF5CF"} innercolor={"#0A2A3E"} />}
              {creator.largestplatform === "TikTok" && <TikTokIcon outercolor={"#FFF5CF"} innercolor={"#0A2A3E"} />}
              {creator.largestplatform === "Instagram" && (
                <InstagramIcon outercolor={"#FFF5CF"} innercolor={"#0A2A3E"} />
              )}
              <p>{creator.largestplatform}</p>
            </div>
          </div> */}
                </div>
                <p className="text-[1.25vw]">{creator.bio}</p>
                <a href={"mailto:" + creator.email} className="email-tile">
                    Contact me!
                </a>
            </div>
            <div id="social_selector" className="h-[18vh] w-[100vw] flex items-center justify-center">
                <Switch>
                    <span className="w-1/2 flex items-center justify-center">
                        <SwitchRadio type="radio" name="switch" checked={selected === "TikTok"} readOnly />
                        <SwitchLabel onClick={() => setSelected("TikTok")} className="!items-center !leading-[4vh]">
                            <TikTokIcon outercolor={"#FFF5CF"} innercolor={"#0A2A3E"} />
                            TikTok
                        </SwitchLabel>
                    </span>
                    <span className="w-1/2 flex items-center justify-center">
                        <SwitchRadio type="radio" name="switch" checked={selected === "Instagram"} readOnly />
                        <SwitchLabel onClick={() => setSelected("Instagram")}>
                            <InstagramIcon outercolor={"#FFF5CF"} innercolor={"#0A2A3E"} />
                            Instagram
                        </SwitchLabel>
                    </span>
                    <SwitchSelection style={{ left: `${selected === "TikTok" ? 1 : 49}%` }} />
                </Switch>
                {selected === "TikTok" && <div class="mt-[2vh]">@{creator.tthandle}</div>}
            </div>
            <div id="collab_and_stats" className="flex flex-col items-center !w-screen p-[2vw]">
                {/* Recent Posts Section */}
                {selected === "TikTok" ? (
                    creator.analytics.top_three_vids && (
                        <div className="section recent-posts gap-[2vw]">
                            <h2 className="text-4xl font-semibold">Recent Posts</h2>
                            <div id="recentposts" className="flex-row justify-evenly items-center">
                                {creator.analytics.top_three_vids.map((video, index) => (
                                    <div key={index} className="post-tile relative">
                                        {video.video_url ? (
                                            <video
                                                id={`video-element-${index}`}
                                                src={video.video_url}
                                                // controls
                                                loop
                                                muted
                                                // if video is playing, onclick should pause it
                                                onClick={() => handlePlayPause(index)}
                                            ></video>
                                        ) : (
                                            <img src={video.thumbnail} alt="post" />
                                        )}
                                        {video.video_url && !isPlaying[index] && (
                                            <CgPlayButton
                                                className="play-button z-10 opacity-60"
                                                size={"70%"}
                                                color="white"
                                                onClick={() => handlePlayPause(index)}
                                            />
                                        )}
                                        {video.video_url && (
                                            <div className="vid_stats tt_stats">
                                                <div className="absolute">
                                                    {isPlaying[index] &&
                                                        (isMuted[index] ? (
                                                            <IoMdVolumeOff
                                                                className="volume-icon z-10 opacity-90 cursor-pointer"
                                                                size={"5vw"}
                                                                color="white"
                                                                onClick={() => handleMute(index)}
                                                            />
                                                        ) : (
                                                            <IoMdVolumeHigh
                                                                className="volume-icon z-10 opacity-90 cursor-pointer"
                                                                size={"5vw"}
                                                                color="white"
                                                                onClick={() => handleMute(index)}
                                                            />
                                                        ))}
                                                </div>
                                                <div>
                                                    <FaThumbsUp className="stat-icons" />
                                                    <p className="">
                                                        {Intl.NumberFormat("en-US", {
                                                            notation: "compact",
                                                            maximumFractionDigits: 1,
                                                        }).format(video.likes)}
                                                    </p>
                                                </div>
                                                <div>
                                                    <FaComment className="stat-icons" />
                                                    <p className="">
                                                        {Intl.NumberFormat("en-US", {
                                                            notation: "compact",
                                                            maximumFractionDigits: 1,
                                                        }).format(video.comments)}
                                                    </p>
                                                </div>
                                                <p className="">
                                                    {Intl.NumberFormat("en-US", {
                                                        notation: "compact",
                                                        maximumFractionDigits: 1,
                                                    }).format(video.views)}{" "}
                                                    views
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )
                ) : (
                    // instagram is showing, so show skeleton until data is loaded
                    <div className="section recent-posts gap-[2vw]">
                        <div class="flex flex-row place-content-start gap-[1.5vw] items-center">
                            <h2 className="text-4xl font-semibold">Recent Posts</h2>
                            <TikTokIcon outercolor={"#0A2A3E"} innercolor={"#FFF5CF"} />
                        </div>

                        <div id="recentposts" className="flex-row justify-evenly items-center">
                            {igloading
                                ? [0, 1, 2].map((_, index) => (
                                      <div key={index} className="post-tile relative">
                                          <Skeleton
                                              className="ig-vid-skeleton"
                                              height={"45vh"}
                                              highlightColor="#97a2b5"
                                              baseColor="#97a2b5"
                                          />
                                          <div className="vid_stats">
                                              <div>
                                                  <FaThumbsUp className="stat-icons" />
                                                  <Skeleton
                                                      className="ig-text-skeleton"
                                                      height={"3vh"}
                                                      baseColor="#97a2b5"
                                                      highlightColor="#97a2b5"
                                                  />
                                              </div>
                                              <div>
                                                  <FaComment className="stat-icons" />
                                                  <Skeleton
                                                      className="ig-text-skeleton"
                                                      height={"3vh"}
                                                      baseColor="#97a2b5"
                                                      highlightColor="#97a2b5"
                                                  />
                                              </div>
                                              <div>
                                                  <Skeleton
                                                      className="ig-text-skeleton"
                                                      height={"3vh"}
                                                      baseColor="#97a2b5"
                                                      highlightColor="#97a2b5"
                                                  />
                                              </div>
                                          </div>
                                      </div>
                                  ))
                                : creator.ig?.top_three_posts?.map((post, index) => (
                                      <div key={index} className="post-tile relative">
                                          {post.media_type === "VIDEO" ? (
                                              <video
                                                  id={`video-element-${index}`}
                                                  src={post.media_url}
                                                  // controls
                                                  loop
                                                  muted
                                                  style={{ borderRadius: "20px 20px 0 0" }}
                                                  // if video is playing, onclick should pause it
                                                  onClick={() => handlePlayPause(index)}
                                              ></video>
                                          ) : (
                                              <img
                                                  src={post.media_url}
                                                  alt="post"
                                                  style={{ borderRadius: "20px 20px 0 0" }}
                                              />
                                          )}
                                          {post.media_type === "VIDEO" && !isPlaying[index] && (
                                              <CgPlayButton
                                                  className="play-button z-10 opacity-60"
                                                  size={"70%"}
                                                  color="white"
                                                  onClick={() => handlePlayPause(index)}
                                              />
                                          )}
                                          {post.media_type === "VIDEO" &&
                                              isPlaying[index] &&
                                              (isMuted[index] ? (
                                                  <IoMdVolumeOff
                                                      className="volume-icon z-10 opacity-90 cursor-pointer"
                                                      size={"5vw"}
                                                      color="white"
                                                      onClick={() => handleMute(index)}
                                                  />
                                              ) : (
                                                  <IoMdVolumeHigh
                                                      className="volume-icon z-10 opacity-90 cursor-pointer"
                                                      size={"5vw"}
                                                      color="white"
                                                      onClick={() => handleMute(index)}
                                                  />
                                              ))}
                                          <div className="ig_stats">
                                              <div>
                                                  <FaThumbsUp className="stat-icons" />
                                                  <p className="">
                                                      {Intl.NumberFormat("en-US", {
                                                          notation: "compact",
                                                          maximumFractionDigits: 1,
                                                      }).format(post.like_count)}
                                                  </p>
                                              </div>
                                              <div>
                                                  <FaComment className="stat-icons" />
                                                  <p className="">
                                                      {Intl.NumberFormat("en-US", {
                                                          notation: "compact",
                                                          maximumFractionDigits: 1,
                                                      }).format(post.comments_count)}
                                                  </p>
                                              </div>
                                              <p className="">
                                                  {Intl.NumberFormat("en-US", {
                                                      notation: "compact",
                                                      maximumFractionDigits: 1,
                                                  }).format(post.insights.data[0].values[0].value)}{" "}
                                                  views
                                              </p>
                                          </div>
                                      </div>
                                  ))}
                        </div>
                    </div>
                )}
                {/* Brand Collaborations Section */}
                {(creator.firstbrand || creator.secondbrand || creator.thirdbrand) && (
                    <div className="section collaborations gap-[2vw]">
                        <h2 className="text-4xl font-semibold pt-[4vh]">Brand Collaborations</h2>
                        <div className="carousel flex-row">
                            {creator.fblink && (
                                <a href={creator.fblink}>
                                    <button className="collab-tile">{creator.firstbrand}</button>
                                </a>
                            )}
                            {creator.sblink && (
                                <a href={creator.sblink}>
                                    <button className="collab-tile">{creator.secondbrand}</button>
                                </a>
                            )}
                            {creator.tblink && (
                                <a href={creator.tblink}>
                                    <button className="collab-tile">{creator.thirdbrand}</button>
                                </a>
                            )}
                        </div>
                    </div>
                )}
                {!(creator.firstbrand || creator.secondbrand || creator.thirdbrand) && (
                    <div className="section collaborations gap-[2vw]">
                        <h2 className="text-4xl font-semibold pt-[4vh]">Brand Collaborations</h2>
                        <div className="items-center">
                            <p>Coming Soon!</p>
                        </div>
                    </div>
                )}
                {/* <div className="section collaborations"> */}
                {/* <h2>Brand Collaborations</h2> */}
                {/* Carousel component would be used here */}
                {/* <div className="carousel">Carousel items go here</div> */}
                {/* </div> */}
                {/* Statistics Section */}
                <div className="section statistics gap-[2vw]">
                    <h2 className=" text-4xl font-semibold">Account Analytics</h2>
                    <div id="stats_grid" className="stats-grid">
                        <div className="stat-tile">
                            <img
                                src={require("../assets/shapes/followericon.svg").default}
                                alt="follower"
                                className="stat-icons"
                            />
                            <div>
                                Followers
                                <h2 className=" text-4xl font-semibold">
                                    {selected === "TikTok" ? (
                                        creator.analytics.followers?.toLocaleString()
                                    ) : selected === "Instagram" && igloading ? (
                                        <Skeleton
                                            height={"2.25rem"}
                                            baseColor="#97a2b5"
                                            highlightColor="#97a2b5"
                                            className="ig-text-skeleton"
                                        />
                                    ) : (
                                        creator.ig?.followers?.toLocaleString()
                                    )}
                                </h2>
                            </div>
                        </div>
                        <div className="stat-tile">
                            <img
                                src={require("../assets/shapes/engagement_rate.svg").default}
                                alt="engagement"
                                className="stat-icons"
                            />
                            <div>
                                {selected === "TikTok" ? "Engagement Rate" : "Accounts Engaged"}
                                <h2 className=" text-4xl font-semibold">
                                    {selected === "TikTok" ? (
                                        creator.analytics.engagement_rate?.toLocaleString() + "%"
                                    ) : selected === "Instagram" && igloading ? (
                                        <Skeleton
                                            height={"2.25rem"}
                                            baseColor="#97a2b5"
                                            highlightColor="#97a2b5"
                                            className="ig-text-skeleton"
                                        />
                                    ) : (
                                        creator.ig?.accounts_engaged?.toLocaleString()
                                    )}
                                </h2>
                            </div>
                        </div>
                        <div className="stat-tile">
                            <img
                                src={require("../assets/shapes/likes_icon.svg").default}
                                alt="likes"
                                className="stat-icons"
                            />
                            <div>
                                {selected === "TikTok" ? "Average Likes" : "Likes"}
                                <h2 className=" text-4xl font-semibold">
                                    {selected === "TikTok" ? (
                                        creator.analytics.avglikes?.toLocaleString()
                                    ) : selected === "Instagram" && igloading ? (
                                        <Skeleton
                                            height={"2.25rem"}
                                            baseColor="#97a2b5"
                                            highlightColor="#97a2b5"
                                            className="ig-text-skeleton"
                                        />
                                    ) : (
                                        creator.ig?.likes?.toLocaleString()
                                    )}
                                </h2>
                            </div>
                        </div>
                        <div className="stat-tile">
                            <img
                                src={require("../assets/shapes/comments_icon.svg").default}
                                alt="comments"
                                className="stat-icons"
                            />
                            <div>
                                {selected === "TikTok" ? "Average Comments" : "Comments"}
                                <h2 className=" text-4xl font-semibold">
                                    {selected === "TikTok" ? (
                                        creator.analytics.avgcomments?.toLocaleString()
                                    ) : selected === "Instagram" && igloading ? (
                                        <Skeleton
                                            height={"2.25rem"}
                                            baseColor="#97a2b5"
                                            highlightColor="#97a2b5"
                                            className="ig-text-skeleton"
                                        />
                                    ) : (
                                        creator.ig?.comments?.toLocaleString()
                                    )}
                                </h2>
                            </div>
                        </div>
                        <div className="stat-tile">
                            <img
                                src={require("../assets/shapes/views_icon.svg").default}
                                alt="views"
                                className="stat-icons"
                            />
                            <div>
                                {selected === "TikTok" ? "Average Views" : "Views"}
                                <h2 className=" text-4xl font-semibold">
                                    {selected === "TikTok" ? (
                                        creator.analytics.avgviews?.toLocaleString()
                                    ) : selected === "Instagram" && igloading ? (
                                        <Skeleton
                                            height={"2.25rem"}
                                            baseColor="#97a2b5"
                                            highlightColor="#97a2b5"
                                            className="ig-text-skeleton"
                                        />
                                    ) : (
                                        creator.ig?.impressions?.toLocaleString()
                                    )}
                                </h2>
                            </div>
                        </div>
                        <div className="stat-tile">
                            <img
                                src={require("../assets/shapes/shares_icon.svg").default}
                                alt="shares"
                                className="stat-icons"
                            />
                            <div>
                                {selected === "TikTok" ? "Average Shares" : "Shares"}
                                <h2 className=" text-4xl font-semibold">
                                    {selected === "TikTok" ? (
                                        creator.analytics.avgshares?.toLocaleString()
                                    ) : selected === "Instagram" && igloading ? (
                                        <Skeleton
                                            height={"2.25rem"}
                                            baseColor="#97a2b5"
                                            highlightColor="#97a2b5"
                                            className="ig-text-skeleton"
                                        />
                                    ) : (
                                        creator.ig?.shares?.toLocaleString()
                                    )}
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {selected === "TikTok" && (
                <div className="!fixed bottom-[2vh] right-[2vh] flex flex-row items-center gap-[0.5vw] bg-[#0A2A3E] text-[#FFF5CF] p-[1vh] px-[1vw] rounded-2xl z-50">
                    <RefreshIcon
                        className={`w-[4vh] h-[4vh] cursor-pointer ${isRefreshing ? "refreshicon" : ""}`}
                        id="refreshicon"
                        style={{ fill: "#FFF5CF" }}
                        onClick={() => {
                            setIsRefreshing(true);
                            fetch(
                                (process.env.NODE_ENV === "development"
                                    ? "http://127.0.0.1:8080"
                                    : "https://abi-backend-b4g3na7wyq-uk.a.run.app/") +
                                    "/?username=" +
                                    creator.tthandle
                            )
                                .then((response) => response.json())
                                .then(async (data) => {
                                    console.log("data:", data);
                                    const { data: priordata, error: priorerror } = await supabase
                                        .from("users")
                                        .select("analytics")
                                        .eq("handle", creatorhandle)
                                        .single();
                                    console.log("priordata:", priordata);
                                    if (priorerror) {
                                        console.error("Error fetching creator data:", priorerror);
                                        toastError("Error fetching creator data. Please try again later.");
                                        setIsRefreshing(false);
                                        return;
                                    }
                                    const { data: updatedata, error: updateerror } = await supabase
                                        .from("users")
                                        .update({
                                            analytics: { ...priordata.analytics, ...data, lastrefreshed: new Date() },
                                        })
                                        .eq("handle", creatorhandle)
                                        .select("*")
                                        .single();
                                    if (updateerror) {
                                        console.error("Error updating creator data:", updateerror);
                                        toastError("Error updating creator data. Please try again later.");
                                        setIsRefreshing(false);
                                        return;
                                    }
                                    console.log("updatedata:", updatedata);
                                    setCreator((prev) => ({ ...prev, ...updatedata }));
                                    setIsRefreshing(false);
                                })
                                .catch((error) => {
                                    console.error("Error fetching creator data:", error);
                                    toastError("Error fetching creator data. Please try again later.");
                                    setIsRefreshing(false);
                                });
                        }}
                    />
                    Last Refreshed: {new Date(creator.analytics?.lastrefreshed).toLocaleString()}
                </div>
            )}
            {/* Rates Section */}
            <div className="section rates">
                <h2 className="text-4xl font-semibold text-[#FFF5CF] pt-[4vh]">Rates</h2>
                <div id="rates_grid" className="flex flex-row w-full mt-[2vw]">
                    <div className="rate-tile bg-[#FFF5CF] text-[#0A2A3E] p-[2vw] rounded-lg shadow-md w-1/3 mx-[2vw] flex flex-col items-center justify-center">
                        <p className="mt-[3vh] mb-[5vh]">${creator.ttrate} | Sponsored TikTok post</p>
                        <TikTokIcon outercolor={"#0A2A3E"} innercolor={"#FFFFFF"} />
                    </div>
                    {/* <div className="rate-tile bg-[#FFF5CF] text-[#0A2A3E] p-[2vw] rounded-lg shadow-md w-1/3 mx-[2vw] flex flex-col items-center justify-center">
                        <p className="mt-[3vh] mb-[1vh]">${creator.igprate} | Sponsored Instagram Post</p>
                        <p className="mb-[5vh]">${creator.igsrate} | Sponsored Instagram Story</p>
                        <InstagramIcon outercolor={"#0A2A3E"} innercolor={"#FFFFFF"} />
                    </div> */}
                    <div className="rate-tile bg-[#FFF5CF] text-[#0A2A3E] p-[2vw] rounded-lg shadow-md w-1/3 mx-[2vw] flex flex-col items-center justify-center">
                        <p className="mt-[3vh] mb-[5vh]">${creator.cprate} | Sponsored Crossposting Content</p>
                        <YouTubeIcon outercolor={"#0A2A3E"} innercolor={"#FFFFFF"} />
                    </div>
                </div>
            </div>

            {/* Most Viewed Content Section */}
            {/* <div className="section most-viewed">
                <h2>Most Viewed Content</h2>
                <div className="most-viewed-content">Most viewed content items go here</div>
            </div> */}

            {/* Share Link Section */}
            <div className="section link p-[1vh] items-center">
                <button
                    className="link-tile"
                    onClick={() => {
                        navigator.clipboard.writeText(window.location.href);
                        toastSuccess("Link copied to clipboard");
                    }}
                >
                    Copy Link to Clipboard
                </button>
            </div>
        </div>
    ) : (
        <p id="creatornotfound">Creator not found</p>
    );
}
