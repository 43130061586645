export default function TiktokIcon({ outercolor, innercolor }) {
    return (
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.0911 11.5C22.0911 17.5836 17.2494 22.5 11.2956 22.5C5.34175 22.5 0.5 17.5836 0.5 11.5C0.5 5.41637 5.34175 0.5 11.2956 0.5C17.2494 0.5 22.0911 5.41637 22.0911 11.5Z"
                fill={outercolor}
                stroke={outercolor}
            />
            <path d="M11.4149 5.1665C12.1273 5.15527 12.8356 5.16192 13.5431 5.15527C13.5859 6.00359 13.8856 6.8677 14.4955 7.46746C15.1042 8.08219 15.9652 8.36357 16.8029 8.45876V10.6903C16.0179 10.6641 15.2291 10.4979 14.5167 10.1537C14.2065 10.0107 13.9174 9.82662 13.6345 9.63833C13.6308 11.2577 13.641 12.8749 13.6243 14.4876C13.5819 15.2623 13.3308 16.0333 12.8882 16.6717C12.1763 17.7345 10.9405 18.4274 9.67125 18.449C8.89272 18.4943 8.11501 18.2782 7.4516 17.88C6.35219 17.22 5.57856 16.0117 5.46589 14.7149C5.45282 14.4377 5.44833 14.1609 5.45935 13.8903C5.55733 12.8358 6.06969 11.8271 6.86495 11.1409C7.76636 10.3416 9.02907 9.96087 10.2114 10.1861C10.2224 11.007 10.1901 11.8271 10.1901 12.648C9.65002 12.4701 9.01887 12.5199 8.54693 12.8537C8.20237 13.0811 7.94068 13.4294 7.80433 13.8234C7.69165 14.1044 7.72391 14.4165 7.73044 14.7149C7.85985 15.6243 8.7188 16.3887 9.63573 16.306C10.2436 16.2993 10.8262 15.9402 11.143 15.4144C11.2455 15.2303 11.3602 15.042 11.3663 14.8255C11.4198 13.8342 11.3985 12.847 11.4051 11.8558C11.4096 9.62171 11.3985 7.39389 11.4153 5.16691L11.4149 5.1665Z"
                fill={innercolor}
            />
        </svg>
    )
}