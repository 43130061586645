import React from "react";

const PrivacyAgreement = () => {
    return (
        <div className="flex flex-col p-4 max-w-3xl mx-auto bg-white shadow-md rounded-md">
            <h1 className="text-2xl font-bold mb-4">PRIVACY NOTICE</h1>
            <p className="mb-4">Last updated 7/1/2024</p>

            <p className="mb-4">
                This privacy notice for A Better Influence LLC ("we," "us," or "our"), describes how and why we might
                collect, store, use, and/or share ("process") your information when you use our services ("Services"),
                such as when you:
            </p>
            <ul className="list-disc list-inside mb-4">
                <li>
                    Visit our website at https://abetterinfluence.com, or any website of ours that links to this privacy
                    notice
                </li>
                <li>Engage with us in other related ways, including any sales, marketing, or events</li>
            </ul>
            <p className="mb-4">
                Questions or concerns? Reading this privacy notice will help you understand your privacy rights and
                choices. If you do not agree with our policies and practices, please do not use our Services.
            </p>

            <h2 className="text-xl font-semibold mt-4">SUMMARY OF KEY POINTS</h2>
            <p className="mb-4">
                This summary provides key points from our privacy notice, but you can find out more details about any of
                these topics by clicking the link following each key point or by using our table of contents below to
                find the section you are looking for.
            </p>
            <ul className="list-disc list-inside mb-4">
                <li>
                    What personal information do we process? Learn more about personal information you disclose to us.
                </li>
                <li>
                    Do we process any sensitive personal information? Learn more about sensitive information we process.
                </li>
                <li>
                    Do we collect any information from third parties? Learn more about information collected from other
                    sources.
                </li>
                <li>How do we process your information? Learn more about how we process your information.</li>
                <li>
                    In what situations and with which types of parties do we share personal information? Learn more
                    about when and with whom we share your personal information.
                </li>
                <li>What are your rights? Learn more about your privacy rights.</li>
                <li>How do you exercise your rights? Learn more about how to exercise your rights.</li>
                <li>
                    Want to learn more about what we do with any information we collect? Review the privacy notice in
                    full.
                </li>
            </ul>

            <h2 className="text-xl font-semibold mt-4">TABLE OF CONTENTS</h2>
            <ul className="list-decimal list-inside mb-4">
                <li>
                    <a className="text-sky-500" href="#information-collection">
                        WHAT INFORMATION DO WE COLLECT?
                    </a>
                </li>
                <li>
                    <a className="text-sky-500" href="#process-information">
                        HOW DO WE PROCESS YOUR INFORMATION?
                    </a>
                </li>
                <li>
                    <a className="text-sky-500" href="#legal-bases">
                        WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?
                    </a>
                </li>
                <li>
                    <a className="text-sky-500" href="#sharing-information">
                        WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                    </a>
                </li>
                <li>
                    <a className="text-sky-500" href="#cookies-tracking">
                        DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                    </a>
                </li>
                <li>
                    <a className="text-sky-500" href="#social-logins">
                        HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                    </a>
                </li>
                <li>
                    <a className="text-sky-500" href="#international-transfer">
                        IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
                    </a>
                </li>
                <li>
                    <a className="text-sky-500" href="#information-retention">
                        HOW LONG DO WE KEEP YOUR INFORMATION?
                    </a>
                </li>
                <li>
                    <a className="text-sky-500" href="#minors">
                        DO WE COLLECT INFORMATION FROM MINORS?
                    </a>
                </li>
                <li>
                    <a className="text-sky-500" href="#privacy-rights">
                        WHAT ARE YOUR PRIVACY RIGHTS?
                    </a>
                </li>
                <li>
                    <a className="text-sky-500" href="#dnt">
                        CONTROLS FOR DO-NOT-TRACK FEATURES
                    </a>
                </li>
                <li>
                    <a className="text-sky-500" href="#us-privacy">
                        DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                    </a>
                </li>
                <li>
                    <a className="text-sky-500" href="#updates">
                        DO WE MAKE UPDATES TO THIS NOTICE?
                    </a>
                </li>
                <li>
                    <a className="text-sky-500" href="#contact">
                        HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                    </a>
                </li>
                <li>
                    <a className="text-sky-500" href="#review-update-delete">
                        HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
                    </a>
                </li>
            </ul>

            <h2 id="information-collection" className="text-xl font-semibold mt-4">
                1. WHAT INFORMATION DO WE COLLECT?
            </h2>
            <h3 className="text-lg font-semibold mt-2">Personal information you disclose to us</h3>
            <p className="mb-4">
                In Short: We collect personal information that you provide to us. We collect personal information that
                you voluntarily provide to us when you register on the Services, express an interest in obtaining
                information about us or our products and Services, when you participate in activities on the Services,
                or otherwise when you contact us.
            </p>
            <h3 className="text-lg font-semibold mt-2">Personal Information Provided by You.</h3>
            <p className="mb-4">
                The personal information that we collect depends on the context of your interactions with us and the
                Services, the choices you make, and the products and features you use. The personal information we
                collect may include the following:
            </p>
            <ul className="list-disc list-inside mb-4">
                <li>names</li>
                <li>phone numbers</li>
                <li>email addresses</li>
                <li>usernames</li>
                <li>passwords</li>
                <li>debit/credit card numbers</li>
                <li>mailing addresses</li>
                <li>contact preferences</li>
                <li>social media handles</li>
                <li>billing addresses</li>
                <li>contact or authentication data</li>
                <li>job titles</li>
            </ul>
            <h3 className="text-lg font-semibold mt-2">Sensitive Information</h3>
            <p className="mb-4">
                When necessary, with your consent or as otherwise permitted by applicable law, we process the following
                categories of sensitive information:
            </p>
            <ul className="list-disc list-inside mb-4">
                <li>information revealing race or ethnic origin</li>
                <li>information revealing political opinions</li>
                <li>data about a person's sex life or sexual orientation</li>
                <li>information revealing religious or philosophical beliefs</li>
                <li>financial data</li>
                <li>student data</li>
            </ul>
            <h3 className="text-lg font-semibold mt-2">Payment Data</h3>
            <p className="mb-4">
                We may collect data necessary to process your payment if you choose to make purchases, such as your
                payment instrument number, and the security code associated with your payment instrument. All payment
                data is handled and stored by Stripe. You may find their privacy notice link(s) here: https://stripe.com/privacy.
            </p>
            <h3 className="text-lg font-semibold mt-2">Social Media Login Data</h3>
            <p className="mb-4">
                We may provide you with the option to register with us using your existing social media account details,
                like your Facebook, X, or other social media account. If you choose to register in this way, we will
                collect certain profile information about you from the social media provider, as described in the
                section called "HOW DO WE HANDLE YOUR SOCIAL LOGINS?" below.
            </p>
            <p className="mb-4">
                All personal information that you provide to us must be true, complete, and accurate, and you must
                notify us of any changes to such personal information.
            </p>

            <h3 className="text-lg font-semibold mt-2">Information automatically collected</h3>
            <p className="mb-4">
                In Short: Some information — such as your Internet Protocol (IP) address and/or browser and device
                characteristics — is collected automatically when you visit our Services. We automatically collect
                certain information when you visit, use, or navigate the Services. This information does not reveal your
                specific identity (like your name or contact information) but may include device and usage information,
                such as your IP address, browser and device characteristics, operating system, language preferences,
                referring URLs, device name, country, location, information about how and when you use our Services, and
                other technical information. This information is primarily needed to maintain the security and operation
                of our Services, and for our internal analytics and reporting purposes.
            </p>
            <p className="mb-4">
                Like many businesses, we also collect information through cookies and similar technologies.
            </p>
            <h3 className="text-lg font-semibold mt-2">The information we collect includes:</h3>
            <ul className="list-disc list-inside mb-4">
                <li>
                    Log and Usage Data. Log and usage data is service-related, diagnostic, usage, and performance
                    information our servers automatically collect when you access or use our Services and which we
                    record in log files. Depending on how you interact with us, this log data may include your IP
                    address, device information, browser type, and settings and information about your activity in the
                    Services (such as the date/time stamps associated with your usage, pages and files viewed, searches,
                    and other actions you take such as which features you use), device event information (such as system
                    activity, error reports (sometimes called "crash dumps"), and hardware settings).
                </li>
                <li>
                    Location Data. We collect location data such as information about your device's location, which can
                    be either precise or imprecise. How much information we collect depends on the type and settings of
                    the device you use to access the Services. For example, we may use GPS and other technologies to
                    collect geolocation data that tells us your current location (based on your IP address). You can opt
                    out of allowing us to collect this information either by refusing access to the information or by
                    disabling your Location setting on your device. However, if you choose to opt out, you may not be
                    able to use certain aspects of the Services.
                </li>
                <li>
                    Social Media Data. Posts made through ABI partnerships will be monitored, collected, and shared to
                    the patterning body, by ABI.
                </li>
            </ul>
            <h3 className="text-lg font-semibold mt-2">Information collected from other sources</h3>
            <p className="mb-4">
                In Short: We may collect limited data from public databases, marketing partners, social media platforms,
                and other outside sources. In order to enhance our ability to provide relevant marketing, offers, and
                services to you and update our records, we may obtain information about you from other sources, such as
                public databases, joint marketing partners, affiliate programs, data providers, social media platforms,
                and from other third parties. This information includes mailing addresses, job titles, email addresses,
                phone numbers, intent data (or user behavior data), Internet Protocol (IP) addresses, social media
                profiles, social media URLs, and custom profiles, for purposes of targeted advertising and event
                promotion. If you interact with us on a social media platform using your social media account (e.g.,
                Facebook or X), we receive personal information about you from such platforms such as your name, email
                address, and gender. Any personal information that we collect from your social media account depends on
                your social media account's privacy settings. Please note that their own use of your information is not
                governed by this privacy notice.
            </p>

            <h2 id="process-information" className="text-xl font-semibold mt-4">
                2. HOW DO WE PROCESS YOUR INFORMATION?
            </h2>
            <p className="mb-4">
                In Short: We process your information to provide, improve, and administer our Services, communicate with
                you, for security and fraud prevention, and to comply with law. We may also process your information for
                other purposes with your consent. We process your personal information for a variety of reasons,
                depending on how you interact with our Services, including:
            </p>
            <ul className="list-disc list-inside mb-4">
                <li>
                    To facilitate account creation and authentication and otherwise manage user accounts. We may process
                    your information so you can create and log in to your account, as well as keep your account in
                    working order.
                </li>
                <li>
                    To deliver and facilitate delivery of services to the user. We may process your information to
                    provide you with the requested service.
                </li>
                <li>
                    To respond to user inquiries/offer support to users. We may process your information to respond to
                    your inquiries and solve any potential issues you might have with the requested service.
                </li>
                <li>
                    To fulfill and manage your orders. We may process your information to fulfill and manage your
                    orders, payments, returns, and exchanges made through the Services.
                </li>
                <li>
                    To enable user-to-user communications. We may process your information if you choose to use any of
                    our offerings that allow for communication with another user.
                </li>
                <li>
                    To request feedback. We may process your information when necessary to request feedback and to
                    contact you about your use of our Services.
                </li>
                <li>
                    To send you marketing and promotional communications. We may process the personal information you
                    send to us for our marketing purposes, if this is in accordance with your marketing preferences. You
                    can opt out of our marketing emails at any time. For more information, see "WHAT ARE YOUR PRIVACY
                    RIGHTS?" below.
                </li>
                <li>
                    To protect our Services. We may process your information as part of our efforts to keep our Services
                    safe and secure, including fraud monitoring and prevention.
                </li>
                <li>
                    To identify usage trends. We may process information about how you use our Services to better
                    understand how they are being used so we can improve them.
                </li>
                <li>
                    To determine the effectiveness of our marketing and promotional campaigns. We may process your
                    information to better understand how to provide marketing and promotional campaigns that are most
                    relevant to you.
                </li>
                <li>
                    To save or protect an individual's vital interest. We may process your information when necessary to
                    save or protect an individual’s vital interest, such as to prevent harm.
                </li>
                <li>To determine success of platform. Figure out if captained through ABI are successful</li>
                <li>
                    To create partnerships. Use to connect the best influencers to the best companies for them and vice
                    versa.
                </li>
            </ul>

            <h2 id="legal-bases" className="text-xl font-semibold mt-4">
                3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
            </h2>
            <p className="mb-4">
                In Short: We only process your personal information when we believe it is necessary and we have a valid
                legal reason (i.e., legal basis) to do so under applicable law, like with your consent, to comply with
                laws, to provide you with services to enter into or fulfill our contractual obligations, to protect your
                rights, or to fulfill our legitimate business interests. If you are located in the EU or UK, this
                section applies to you. The General Data Protection Regulation (GDPR) and UK GDPR require us to explain
                the valid legal bases we rely on in order to process your personal information. As such, we may rely on
                the following legal bases to process your personal information:
            </p>
            <ul className="list-disc list-inside mb-4">
                <li>
                    Consent. We may process your information if you have given us permission (i.e., consent) to use your
                    personal information for a specific purpose. You can withdraw your consent at any time. Learn more
                    about withdrawing your consent.
                </li>
                <li>
                    Performance of a Contract. We may process your personal information when we believe it is necessary
                    to fulfill our contractual obligations to you, including providing our Services or at your request
                    prior to entering into a contract with you.
                </li>
                <li>
                    Legitimate Interests. We may process your information when we believe it is reasonably necessary to
                    achieve our legitimate business interests and those interests do not outweigh your interests and
                    fundamental rights and freedoms. For example, we may process your personal information for some of
                    the purposes described in order to:
                    <ul className="list-disc list-inside ml-4">
                        <li>Send users information about special offers and discounts on our products and services</li>
                        <li>Analyze how our Services are used so we can improve them to engage and retain users</li>
                        <li>Support our marketing activities</li>
                        <li>Diagnose problems and/or prevent fraudulent activities</li>
                        <li>
                            Understand how our users use our products and services so we can improve user experience
                        </li>
                    </ul>
                </li>
                <li>
                    Legal Obligations. We may process your information where we believe it is necessary for compliance
                    with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency,
                    exercise or defend our legal rights, or disclose your information as evidence in litigation in which
                    we are involved.
                </li>
                <li>
                    Vital Interests. We may process your information where we believe it is necessary to protect your
                    vital interests or the vital interests of a third party, such as situations involving potential
                    threats to the safety of any person.
                </li>
            </ul>
            <p className="mb-4">
                If you are located in Canada, this section applies to you. We may process your information if you have
                given us specific permission (i.e., express consent) to use your personal information for a specific
                purpose, or in situations where your permission can be inferred (i.e., implied consent). You can
                withdraw your consent at any time. In some exceptional cases, we may be legally permitted under
                applicable law to process your information without your consent, including, for example:
            </p>
            <ul className="list-disc list-inside mb-4">
                <li>
                    If collection is clearly in the interests of an individual and consent cannot be obtained in a
                    timely way
                </li>
                <li>For investigations and fraud detection and prevention</li>
                <li>For business transactions provided certain conditions are met</li>
                <li>
                    If it is contained in a witness statement and the collection is necessary to assess, process, or
                    settle an insurance claim
                </li>
                <li>For identifying injured, ill, or deceased persons and communicating with next of kin</li>
                <li>
                    If we have reasonable grounds to believe an individual has been, is, or may be victim of financial
                    abuse
                </li>
                <li>
                    If it is reasonable to expect collection and use with consent would compromise the availability or
                    the accuracy of the information and the collection is reasonable for purposes related to
                    investigating a breach of an agreement or a contravention of the laws of Canada or a province
                </li>
                <li>
                    If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court
                    relating to the production of records
                </li>
                <li>
                    If it was produced by an individual in the course of their employment, business, or profession and
                    the collection is consistent with the purposes for which the information was produced
                </li>
                <li>If the collection is solely for journalistic, artistic, or literary purposes</li>
                <li>If the information is publicly available and is specified by the regulations</li>
            </ul>

            <h2 id="sharing-information" className="text-xl font-semibold mt-4">
                4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </h2>
            <p className="mb-4">
                In Short: We may share information in specific situations described in this section and/or with the
                following categories of third parties. Vendors, Consultants, and Other Third-Party Service Providers. We
                may share your data with third-party vendors, service providers, contractors, or agents ("third
                parties") who perform services for us or on our behalf and require access to such information to do that
                work. We have contracts in place with our third parties, which are designed to help safeguard your
                personal information. This means that they cannot do anything with your personal information unless we
                have instructed them to do it. They will also not share your personal information with any organization
                apart from us. They also commit to protect the data they hold on our behalf and to retain it for the
                period we instruct.
            </p>
            <h3 className="text-lg font-semibold mt-2">
                The categories of third parties we may share personal information with are as follows:
            </h3>
            <ul className="list-disc list-inside mb-4">
                <li>Cloud Computing Services</li>
                <li>Data Analytics Services</li>
                <li>Data Storage Service Providers</li>
                <li>Communication & Collaboration Tools</li>
                <li>Payment Processors</li>
                <li>Performance Monitoring Tools</li>
                <li>Testing Tools</li>
                <li>Social Networks</li>
                <li>Sales & Marketing Tools</li>
                <li>Finance & Accounting Tools</li>
                <li>Businesses on ABI</li>
            </ul>
            <h3 className="text-lg font-semibold mt-2">
                We also may need to share your personal information in the following situations:
            </h3>
            <ul className="list-disc list-inside mb-4">
                <li>
                    Business Transfers. We may share or transfer your information in connection with, or during
                    negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion
                    of our business to another company.
                </li>
                <li>
                    Affiliates. We may share your information with our affiliates, in which case we will require those
                    affiliates to honor this privacy notice. Affiliates include our parent company and any subsidiaries,
                    joint venture partners, or other companies that we control or that are under common control with us.
                </li>
                <li>
                    Business Partners. We may share your information with our business partners to offer you certain
                    products, services, or promotions.
                </li>
            </ul>

            <h2 id="cookies-tracking" className="text-xl font-semibold mt-4">
                5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            </h2>
            <p className="mb-4">
                In Short: We may use cookies and other tracking technologies to collect and store your information. We
                may use cookies and similar tracking technologies (like web beacons and pixels) to gather information
                when you interact with our Services. Some online tracking technologies help us maintain the security of
                our Services and your account, prevent crashes, fix bugs, save your preferences, and assist with basic
                site functions. We also permit third parties and service providers to use online tracking technologies
                on our Services for analytics and advertising, including to help manage and display advertisements, to
                tailor advertisements to your interests, or to send abandoned shopping cart reminders (depending on your
                communication preferences). The third parties and service providers use their technology to provide
                advertising about products and services tailored to your interests which may appear either on our
                Services or on other websites. To the extent these online tracking technologies are deemed to be a
                "sale"/"sharing" (which includes targeted advertising, as defined under the applicable laws) under
                applicable US state laws, you can opt out of these online tracking technologies by submitting a request
                as described below under section "DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?" Specific
                information about how we use such technologies and how you can refuse certain cookies is set out in our
                Cookie Notice.
            </p>

            <h2 id="social-logins" className="text-xl font-semibold mt-4">
                6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
            </h2>
            <p className="mb-4">
                In Short: If you choose to register or log in to our Services using a social media account, we may have
                access to certain information about you. Our Services offer you the ability to register and log in using
                your third-party social media account details (like your Facebook or X logins). Where you choose to do
                this, we will receive certain profile information about you from your social media provider. The profile
                information we receive may vary depending on the social media provider concerned, but will often include
                your name, email address, friends list, and profile picture, as well as other information you choose to
                make public on such a social media platform. We will use the information we receive only for the
                purposes that are described in this privacy notice or that are otherwise made clear to you on the
                relevant Services. Please note that we do not control, and are not responsible for, other uses of your
                personal information by your third-party social media provider. We recommend that you review their
                privacy notice to understand how they collect, use, and share your personal information, and how you can
                set your privacy preferences on their sites and apps.
            </p>

            <h2 id="international-transfer" className="text-xl font-semibold mt-4">
                7. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
            </h2>
            <p className="mb-4">
                In Short: We may transfer, store, and process your information in countries other than your own. Our
                servers are located in. If you are accessing our Services from outside, please be aware that your
                information may be transferred to, stored, and processed by us in our facilities and by those third
                parties with whom we may share your personal information (see "WHEN AND WITH WHOM DO WE SHARE YOUR
                PERSONAL INFORMATION?" above), in and other countries. If you are a resident in the European Economic
                Area (EEA), United Kingdom (UK), or Switzerland, then these countries may not necessarily have data
                protection laws or other similar laws as comprehensive as those in your country. However, we will take
                all necessary measures to protect your personal information in accordance with this privacy notice and
                applicable law.
            </p>

            <h2 id="information-retention" className="text-xl font-semibold mt-4">
                8. HOW LONG DO WE KEEP YOUR INFORMATION?
            </h2>
            <p className="mb-4">
                In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this
                privacy notice unless otherwise required by law. We will only keep your personal information for as long
                as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is
                required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this
                notice will require us keeping your personal information for longer than. When we have no ongoing
                legitimate business need to process your personal information, we will either delete or anonymize such
                information, or, if this is not possible (for example, because your personal information has been stored
                in backup archives), then we will securely store your personal information and isolate it from any
                further processing until deletion is possible.
            </p>

            <h2 id="minors" className="text-xl font-semibold mt-4">
                9. DO WE COLLECT INFORMATION FROM MINORS?
            </h2>
            <p className="mb-4">
                In Short: We do not knowingly collect data from or market to children under 18 years of age. We do not
                knowingly collect, solicit data from, or market to children under 18 years of age, nor do we knowingly
                sell such personal information. By using the Services, you represent that you are at least 18 or that
                you are the parent or guardian of such a minor and consent to such minor dependent’s use of the
                Services. If we learn that personal information from users less than 18 years of age has been collected,
                we will deactivate the account and take reasonable measures to promptly delete such data from our
                records. If you become aware of any data we may have collected from children under age 18, please
                contact us at ourfuture@abetterinfluence.com.
            </p>

            <h2 id="privacy-rights" className="text-xl font-semibold mt-4">
                10. WHAT ARE YOUR PRIVACY RIGHTS?
            </h2>
            <p className="mb-4">
                In Short: Depending on your state of residence in the US or in some regions, such as the European
                Economic Area (EEA), United Kingdom (UK), Switzerland, and Canada, you have rights that allow you
                greater access to and control over your personal information. You may review, change, or terminate your
                account at any time, depending on your country, province, or state of residence. In some regions (like
                the EEA, UK, Switzerland, and Canada), you have certain rights under applicable data protection laws.
                These may include the right (i) to request access and obtain a copy of your personal information, (ii)
                to request rectification or erasure; (iii) to restrict the processing of your personal information; (iv)
                if applicable, to data portability; and (v) not to be subject to automated decision-making. In certain
                circumstances, you may also have the right to object to the processing of your personal information. You
                can make such a request by contacting us by using the contact details provided in the section "HOW CAN
                YOU CONTACT US ABOUT THIS NOTICE?" below. We will consider and act upon any request in accordance with
                applicable data protection laws.
            </p>
            <p className="mb-4">
                If you are located in the EEA or UK and you believe we are unlawfully processing your personal
                information, you also have the right to complain to your Member State data protection authority or UK
                data protection authority. If you are located in Switzerland, you may contact the Federal Data
                Protection and Information Commissioner. Withdrawing your consent: If we are relying on your consent to
                process your personal information, which may be express and/or implied consent depending on the
                applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent
                at any time by contacting us by using the contact details provided in the section "HOW CAN YOU CONTACT
                US ABOUT THIS NOTICE?" below. However, please note that this will not affect the lawfulness of the
                processing before its withdrawal nor, when applicable law allows, will it affect the processing of your
                personal information conducted in reliance on lawful processing grounds other than consent.
            </p>
            <h3 className="text-lg font-semibold mt-2">Opting out of marketing and promotional communications</h3>
            <p className="mb-4">
                You can unsubscribe from our marketing and promotional communications at any time by clicking on the
                unsubscribe link in the emails that we send, or by contacting us using the details provided in the
                section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below. You will then be removed from the marketing
                lists. However, we may still communicate with you — for example, to send you service-related messages
                that are necessary for the administration and use of your account, to respond to service requests, or
                for other non-marketing purposes.
            </p>
            <h3 className="text-lg font-semibold mt-2">Account Information</h3>
            <p className="mb-4">
                If you would at any time like to review or change the information in your account or terminate your
                account, you can:
            </p>
            <ul className="list-disc list-inside mb-4">
                <li>Log in to your account settings and update your user account.</li>
                <li>
                    Upon your request to terminate your account, we will deactivate or delete your account and
                    information from our active databases. However, we may retain some information in our files to
                    prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or
                    comply with applicable legal requirements.
                </li>
            </ul>

            <h2 id="dnt" className="text-xl font-semibold mt-4">
                11. CONTROLS FOR DO-NOT-TRACK FEATURES
            </h2>
            <p className="mb-4">
                Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track
                ("DNT") feature or setting you can activate to signal your privacy preference not to have data about
                your online browsing activities monitored and collected. At this stage, no uniform technology standard
                for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to
                DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked
                online. If a standard for online tracking is adopted that we must follow in the future, we will inform
                you about that practice in a revised version of this privacy notice. California law requires us to let
                you know how we respond to web browser DNT signals. Because there currently is not an industry or legal
                standard for recognizing or honoring DNT signals, we do not respond to them at this time.
            </p>

            <h2 id="us-privacy" className="text-xl font-semibold mt-4">
                12. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </h2>
            <p className="mb-4">
                In Short: If you are a resident of, you may have the right to request access to and receive details
                about the personal information we maintain about you and how we have processed it, correct inaccuracies,
                get a copy of, or delete your personal information. You may also have the right to withdraw your consent
                to our processing of your personal information. These rights may be limited in some circumstances by
                applicable law. More information is provided below.
            </p>
            <h3 className="text-lg font-semibold mt-2">Categories of Personal Information We Collect</h3>
            <p className="mb-4">
                We have collected the following categories of personal information in the past twelve (12) months:
            </p>
            <table className="table-auto w-full mb-4">
                <thead>
                    <tr>
                        <th className="px-4 py-2">Category</th>
                        <th className="px-4 py-2">Examples</th>
                        <th className="px-4 py-2">Collected</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="border px-4 py-2">A. Identifiers</td>
                        <td className="border px-4 py-2">
                            Contact details, such as real name, alias, postal address, telephone or mobile contact
                            number, unique personal identifier, online identifier, Internet Protocol address, email
                            address, and account name
                        </td>
                        <td className="border px-4 py-2">NO</td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2">
                            B. Protected classification characteristics under state or federal law
                        </td>
                        <td className="border px-4 py-2">
                            Gender, age, date of birth, race and ethnicity, national origin, marital status, and other
                            demographic data
                        </td>
                        <td className="border px-4 py-2">NO</td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2">C. Commercial information</td>
                        <td className="border px-4 py-2">
                            Transaction information, purchase history, financial details, and payment information
                        </td>
                        <td className="border px-4 py-2">NO</td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2">D. Biometric information</td>
                        <td className="border px-4 py-2">Fingerprints and voiceprints</td>
                        <td className="border px-4 py-2">NO</td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2">E. Internet or other similar network activity</td>
                        <td className="border px-4 py-2">
                            Browsing history, search history, online behavior, interest data, and interactions with our
                            and other websites, applications, systems, and advertisements
                        </td>
                        <td className="border px-4 py-2">NO</td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2">F. Geolocation data</td>
                        <td className="border px-4 py-2">Device location</td>
                        <td className="border px-4 py-2">NO</td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2">G. Audio, electronic, sensory, or similar information</td>
                        <td className="border px-4 py-2">
                            Images and audio, video or call recordings created in connection with our business
                            activities
                        </td>
                        <td className="border px-4 py-2">NO</td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2">H. Professional or employment-related information</td>
                        <td className="border px-4 py-2">
                            Business contact details in order to provide you our Services at a business level or job
                            title, work history, and professional qualifications if you apply for a job with us
                        </td>
                        <td className="border px-4 py-2">NO</td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2">I. Education Information</td>
                        <td className="border px-4 py-2">Student records and directory information</td>
                        <td className="border px-4 py-2">NO</td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2">J. Inferences drawn from collected personal information</td>
                        <td className="border px-4 py-2">
                            Inferences drawn from any of the collected personal information listed above to create a
                            profile or summary about, for example, an individual’s preferences and characteristics
                        </td>
                        <td className="border px-4 py-2">NO</td>
                    </tr>
                    <tr>
                        <td className="border px-4 py-2">K. Sensitive personal Information</td>
                        <td className="border px-4 py-2"></td>
                        <td className="border px-4 py-2">NO</td>
                    </tr>
                </tbody>
            </table>
            <p className="mb-4">
                We may also collect other personal information outside of these categories through instances where you
                interact with us in person, online, or by phone or mail in the context of:
            </p>
            <ul className="list-disc list-inside mb-4">
                <li>Receiving help through our customer support channels;</li>
                <li>Participation in customer surveys or contests; and</li>
                <li>Facilitation in the delivery of our Services and to respond to your inquiries.</li>
            </ul>

            <h3 className="text-lg font-semibold mt-2">Sources of Personal Information</h3>
            <p className="mb-4">
                Learn more about the sources of personal information we collect in "WHAT INFORMATION DO WE COLLECT?"
            </p>
            <h3 className="text-lg font-semibold mt-2">How We Use and Share Personal Information</h3>
            <p className="mb-4">
                Learn about how we use your personal information in the section, "HOW DO WE PROCESS YOUR INFORMATION?"
            </p>
            <h3 className="text-lg font-semibold mt-2">Will your information be shared with anyone else?</h3>
            <p className="mb-4">
                We may disclose your personal information with our service providers pursuant to a written contract
                between us and each service provider. Learn more about how we disclose personal information to in the
                section, "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?" We may use your personal
                information for our own business purposes, such as for undertaking internal research for technological
                development and demonstration. This is not considered to be "selling" of your personal information.
            </p>
            <p className="mb-4">
                We have disclosed the following categories of personal information to third parties for a business or
                commercial purpose in the preceding twelve (12) months: The categories of third parties to whom we
                disclosed personal information for a business or commercial purpose can be found under "WHEN AND WITH
                WHOM DO WE SHARE YOUR PERSONAL INFORMATION?" We have sold or shared the following categories of personal
                information to third parties in the preceding twelve (12) months: The categories of third parties to
                whom we sold personal information are: The categories of third parties to whom we shared personal
                information with are:
            </p>
            <ul className="list-disc list-inside mb-4">
                <li>Data Analytics Services</li>
                <li>Retargeting Platforms</li>
                <li>Social Networks</li>
                <li>User Account Registration & Authentication Services</li>
            </ul>

            <h3 className="text-lg font-semibold mt-2">Your Rights</h3>
            <p className="mb-4">
                You have rights under certain US state data protection laws. However, these rights are not absolute, and
                in certain cases, we may decline your request as permitted by law. These rights include:
            </p>
            <ul className="list-disc list-inside mb-4">
                <li>Right to know whether or not we are processing your personal data</li>
                <li>Right to access your personal data</li>
                <li>Right to correct inaccuracies in your personal data</li>
                <li>Right to request the deletion of your personal data</li>
                <li>Right to obtain a copy of the personal data you previously shared with us</li>
                <li>Right to non-discrimination for exercising your rights</li>
                <li>
                    Right to opt out of the processing of your personal data if it is used for targeted advertising, the
                    sale of personal data, or profiling in furtherance of decisions that produce legal or similarly
                    significant effects ("profiling")
                </li>
            </ul>

            <h3 className="text-lg font-semibold mt-2">How to Exercise Your Rights</h3>
            <p className="mb-4">
                To exercise these rights, you can contact us by submitting a data subject access request, by emailing us
                at ourfuture@abetterinfluence.com, or by referring to the contact details at the bottom of this
                document. You can opt out from the selling of your personal information, targeted advertising, or
                profiling by disabling cookies in Cookie Preference Settings. Under certain US state data protection
                laws, you can designate an authorized agent to make a request on your behalf. We may deny a request from
                an authorized agent that does not submit proof that they have been validly authorized to act on your
                behalf in accordance with applicable laws.
            </p>

            <h3 className="text-lg font-semibold mt-2">Request Verification</h3>
            <p className="mb-4">
                Upon receiving your request, we will need to verify your identity to determine you are the same person
                about whom we have the information in our system. We will only use personal information provided in your
                request to verify your identity or authority to make the request. However, if we cannot verify your
                identity from the information already maintained by us, we may request that you provide additional
                information for the purposes of verifying your identity and for security or fraud-prevention purposes.
                If you submit the request through an authorized agent, we may need to collect additional information to
                verify your identity before processing your request and the agent will need to provide a written and
                signed permission from you to submit such request on your behalf.
            </p>

            <h2 id="updates" className="text-xl font-semibold mt-4">
                13. DO WE MAKE UPDATES TO THIS NOTICE?
            </h2>
            <p className="mb-4">
                In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws. We may
                update this privacy notice from time to time. The updated version will be indicated by an updated
                "Revised" date at the top of this privacy notice. If we make material changes to this privacy notice, we
                may notify you either by prominently posting a notice of such changes or by directly sending you a
                notification. We encourage you to review this privacy notice frequently to be informed of how we are
                protecting your information.
            </p>

            <h2 id="contact" className="text-xl font-semibold mt-4">
                14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            </h2>
            <p className="mb-4">
                If you have questions or comments about this notice, you may contact us by email at:
                ourfuture@abetterinfluence.com
            </p>

            <h2 id="review-update-delete" className="text-xl font-semibold mt-4">
                15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
            </h2>
            <p className="mb-4">
                Based on the applicable laws of your country or state of residence in the US, you may have the right to
                request access to the personal information we collect from you, details about how we have processed it,
                correct inaccuracies, or delete your personal information. You may also have the right to withdraw your
                consent to our processing of your personal information. These rights may be limited in some
                circumstances by applicable law. To request to review, update, or delete your personal information,
                please fill out and submit a data subject access request.
            </p>
        </div>
    );
};

export default PrivacyAgreement;
