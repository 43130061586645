import { supabase } from "./supabase";
import toast from "react-hot-toast";

export const baseUrl =
    process.env.NODE_ENV === "development" ? "http://localhost:3001" : "https://betterinfluence.onrender.com";

export async function checkMetadata() {
    const {
        data: { user },
    } = await supabase.auth.getUser();
    if (!user) return {};
    const { data, error } = await supabase.from("users").select("*").eq("email", user.email).single();

    if (error) {
        console.error("Error getting user data:", error);
        return {};
    }

    // console.log("data is:", data);

    const { data: identity_data, error: identity_error } = await supabase.auth.getUserIdentities();
    let fbdata = undefined;
    if (identity_error) {
        console.error("Error getting user identity data:", identity_error);
        // return {};
    } else {
        // console.log("identites:", identities);
        fbdata = identity_data.identities.find((element) => element.provider === "facebook");
    }

    const fb_key = Object.keys(localStorage).find((key) => key.startsWith("fb"));
    const fb_key_text = fb_key ? localStorage.getItem(fb_key) : undefined;

    const sb_key = Object.keys(localStorage).find((key) => key.startsWith("sb"));
    const sb_key_text = sb_key ? JSON.parse(localStorage.getItem(sb_key) || "{}") : {};

    return {
        ...data,
        email: user.email,
        name: data.first_name + " " + data.last_name,
        uuid: data.uuid,
        imglink: data.imglink,
        confirmed: data.confirmed,
        fb_picture: fbdata?.identity_data ? fbdata.identity_data.picture : undefined,
        fb_name: fbdata?.identity_data ? fbdata.identity_data.name : undefined,
        fb_access_token: fb_key_text || sb_key_text.provider_token || data.ig_data?.access_token || undefined,
    };
}

/**
 * Backblaze uploader.
 * @param  {Any} file  File to upload.
 * @param  {String} fileName   Directory path to upload to.
 * @param  {String} folderType Type of folder we upload to.
 * @return {Any}
 */
export async function uploadFileToBackblaze(file: File, fileName: string, folderType: string) {
    try {
        let metadata = await checkMetadata();
        console.log("file is:", file, "\n\nfileName is:", fileName, "\n\nfolderType is:", folderType);
        const sha1Checksum = await calculateSHA1(await readFileContent(file));

        const detailsResponse = await fetch(`${baseUrl}/uploaddetails`);
        if (!detailsResponse.ok) {
            console.error("Failed to get upload details:", detailsResponse.status);
            throw new Error("Failed to get upload details");
        }

        const details = await detailsResponse.json();

        const listData = await fetch(`${baseUrl}/listfilenames?fileName=${fileName}`);
        if (!listData.ok) console.error("Failed to get file id:", listData.status);
        else {
            let listDataJson = await listData.json();
            console.log("listDataJson:", listDataJson);

            // iterate through listDataJson.files, and delete the file that matches the fileName
            for (let i = 0; i < listDataJson.files.length; i++) {
                if (listDataJson.files[i].fileName === fileName) {
                    // delete file
                    console.log("fileName:", fileName, "\n\nfileId:", listDataJson.files[0].fileId);
                    const deleteResponse = await fetch(`${baseUrl}/deletefile`, {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            fileName: fileName,
                            fileId: listDataJson.files[i].fileId,
                        }),
                    });

                    if (!deleteResponse.ok) {
                        console.error("Failed to delete file:", deleteResponse);
                        throw new Error("Failed to delete file");
                    }
                }
            }
        }

        if (process.env.NODE_ENV === "development") console.log("uploading with uploadUrl:", details.uploadUrl);
        const uploadResponse = await fetch(details.uploadUrl, {
            method: "POST",
            headers: {
                Authorization: details.uploadAuthorizationToken,
                "X-Bz-File-Name": fileName,
                "Content-Type": file.type,
                "Content-Length": file.size.toString(),
                "X-Bz-Content-Sha1": sha1Checksum,
            },
            body: file,
        });

        if (uploadResponse.ok) {
            const data = await uploadResponse.json();
            // console.log("data from response:", data);
            const filelink = `https://f002.backblazeb2.com/file/betterinfluence/${data.fileName}`;
            console.log("filelink:", filelink);

            if (folderType === "creators") {
                console.log("for profile images, imglink is:", metadata.imglink, "\n\nemail is:", metadata.email);
                const { error } = await supabase
                    .from("users")
                    .update({ imglink: filelink })
                    .match({ email: metadata.email });
                if (error) {
                    console.error("Error inserting file into database:", error);
                    throw new Error("Error inserting file into database");
                }
            }
            return { link: filelink };
        } else {
            console.error("File upload failed:", uploadResponse.status);
            // Handle the error here
            throw new Error("File upload failed");
        }
    } catch (error) {
        console.error("Error during file upload:", error);
        // Return false on error
        return false;
    }
}

async function calculateSHA1(fileContent: FileReader["result"]): Promise<string> {
    return new Promise((resolve, reject) => {
        if (fileContent instanceof ArrayBuffer === false) {
            reject(new Error("File content is not an ArrayBuffer"));
            return;
        }
        // Convert the ArrayBuffer to a Uint8Array
        const data = new Uint8Array(fileContent);

        // Calculate the SHA-1 hash
        const crypto = window.crypto.subtle;
        crypto
            .digest("SHA-1", data)
            .then((hashBuffer) => {
                const hashArray = Array.from(new Uint8Array(hashBuffer));
                const hashHex = hashArray.map((byte) => byte.toString(16).padStart(2, "0")).join("");
                resolve(hashHex);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

function readFileContent(file: File): Promise<FileReader["result"]> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => {
            const target = event.target;
            if (!target) {
                reject(new Error("Unknown error"));
                return;
            }
            resolve(event.target.result);
        };

        reader.onerror = (event) => {
            const target = event.target;
            if (!target) {
                reject(new Error("Unknown error"));
                return;
            }
            reject(event.target.error);
        };

        reader.readAsArrayBuffer(file);
    });
}

export function toastSuccess(message: string) {
    toast.success(message, {
        position: "top-center",
        style: {
            background: "#61d345",
            color: "#fff",
        },
        iconTheme: {
            primary: "#fff",
            secondary: "#61d345",
        },
    });
}

export function toastError(message: string) {
    toast.error(message, {
        position: "top-center",
        style: {
            background: "#ff4b4b",
            color: "#fff",
        },
        iconTheme: {
            primary: "#fff",
            secondary: "#ff4b4b",
        },
    });
}

export async function toastPromise(promisefunc: Promise<void>, loading: string, success: string) {
    try {
        toast.loading(loading, { duration: Infinity });
        await promisefunc;
        toast.dismiss();
        toast.success(success || "Done!", {
            position: "top-center",
            style: {
                background: "#61d345",
                color: "#fff",
            },
            iconTheme: {
                primary: "#fff",
                secondary: "#61d345",
            },
        });
    } catch (error: unknown) {
        toast.dismiss();
        if (error instanceof Error) {
            toast.error(error.message || `Something went wrong`, {
                position: "top-center",
                style: {
                    background: "red",
                    color: "#fff",
                },
                iconTheme: {
                    primary: "#fff",
                    secondary: "red",
                },
            });
        } else {
            toast.error(`Something went wrong`, {
                position: "top-center",
                style: {
                    background: "red",
                    color: "#fff",
                },
                iconTheme: {
                    primary: "#fff",
                    secondary: "red",
                },
            });
        }
    }
}

export async function hideHeader() {
    const navbar = document.getElementById("navbar");
    if (!navbar) return;
    navbar.style.display = "none";
}

export async function showHeader() {
    const navbar = document.getElementById("navbar");
    if (!navbar) return;
    navbar.style.display = "flex";
}
