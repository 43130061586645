import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../App.js";
import { toastError, toastSuccess } from "../GlobalFunctions.ts";
import { supabase } from "../supabase.js";
import { GlobalStyles } from "../GlobalStyles.js";
import { FaFacebook } from "react-icons/fa6";

export default function LoginScreen() {
    const location = useLocation();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const { login } = useContext(AuthContext);

    useEffect(() => {
        (async () => {
            const searchParams = new URLSearchParams(location.hash.substring(1));
            const accessToken = searchParams.get("access_token");
            const refreshToken = searchParams.get("refresh_token");

            if (accessToken && refreshToken) {
                const { data, error } = await supabase.auth.setSession({
                    access_token: accessToken,
                    refresh_token: refreshToken,
                });
                if (error) {
                    console.error("Error setting session:", error);
                } else {
                    console.log("User logged in with Facebook:", data);
                }
            }
        })();
    }, []);

    return (
        <div id="authform" className="flex flex-col items-center justify-center bg-white p-8 customshadow rounded-xl">
            <p id="signuptitle">LOG IN</p>

            <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                type="email-address"
                autoCapitalize="none"
                autoCorrect="false"
            />

            <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />

            <input
                id="signupbutton"
                type="button"
                className="actionbtn rounded-3xl !text-white !font-semibold !mt-0"
                onClick={async () => {
                    if (email === "" || password === "") {
                        toastError("Please fill out all fields!");
                    } else {
                        await login(email, password);
                    }
                }}
                value="SUBMIT"
            />
            <button
                className="flex flex-row items-center bg-[#3B5998] p-[1vh] rounded-lg gap-[1vw] text-white"
                onClick={async () => {
                    if (email === "") {
                        toastError("Please enter your email before logging in with Facebook.");
                        return;
                    }
                    // if user is not in supabase.from("users"), tell them to fill out pledge on home page
                    // if user is in supabase.from("users"), but confirmed is false, tell them to wait for approval before signing in
                    // if user is in supabase.from("users") and confirmed is true, sign them in
                    const { data: emailData, error: emailError } = await supabase
                        .from("users")
                        .select("*")
                        .eq("email", email)
                        .single();
                    if (emailError && emailError.details === "The result contains 0 rows") {
                        console.error("no email found:", emailError);
                        toastError("Please fill out our pledge and wait for approval before signing in.");
                    } else {
                        if (emailData.confirmed) {
                            const { data, error } = await supabase.auth.signInWithOAuth({
                                provider: "facebook",
                                options: {
                                    redirectTo:
                                        process.env.NODE_ENV === "development"
                                            ? "http://localhost:3000/creatorsettings"
                                            : "https://abetterinfluence.com/creatorsettings",
                                    scopes: "instagram_basic,instagram_content_publish,instagram_manage_insights,pages_show_list,pages_read_engagement,business_management",
                                    // instagram_manage_comments,
                                },
                            });
                            if (error) {
                                console.error("Error linking Facebook account:", error);
                                toastError("Error linking Facebook account. Please try again later.");
                            } else {
                                console.log("fb data:", data);
                            }
                        } else {
                            toastError("We received your pledge. Please wait for approval before signing in.");
                        }
                    }
                }}
            >
                <FaFacebook color="white" />
                Login with Facebook
            </button>

            <Link to="/signup" className="authgrayunderline">
                Don't have an account? Sign up here.
            </Link>
        </div>
    );
}
