import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AuthContext } from "../App.js";
import { hideHeader, showHeader, toastError, toastSuccess } from "../GlobalFunctions.ts";
import "../assets/css/signupscreen.css";
import { supabase } from "../supabase.js";
// import { GlobalStyles } from "../GlobalStyles.js";

export default function SignupScreen() {
    let [searchParams] = useSearchParams();
    const search_email = searchParams.get("email");
    const [email, setEmail] = useState(search_email || "");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const { register } = useContext(AuthContext);

    return (
        <div id="authbox" className="justify-center p-1.5">
            <form
                id="authform"
                className="flex flex-col items-center justify-center bg-white p-8 customshadow rounded-xl w-[85vw]"
            >
                <p id="signuptitle">SIGN UP</p>
                <input
                    value={email || ""}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    type="email-address"
                    autoCapitalize="none"
                    autoCorrect="false"
                    disabled={search_email ? true : false}
                    style={{ cursor: search_email ? "not-allowed" : "auto" }}
                />
                <input
                    value={password || ""}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    type="password"
                    autoCapitalize="none"
                    autoCorrect="false"
                />
                <input
                    value={confirmPassword || ""}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Confirm Password"
                    type="password"
                    autoCapitalize="none"
                    autoCorrect="false"
                />
                <input
                    id="signupbutton"
                    type="button"
                    className="actionbtn rounded-3xl !text-white !font-semibold"
                    onClick={async () => {
                        if (email === "" || password === "" || confirmPassword === "")
                            toastError("Please fill out all fields!");
                        else {
                            if (password !== confirmPassword) {
                                toastError("Passwords do not match!");
                                return;
                            }
                            // check to see if email is in users
                            const { data: emailData, error: emailError } = await supabase
                                .from("users")
                                .select("*")
                                .eq("email", email)
                                .single();
                            if (emailError) {
                                console.error("no email found:", emailError);
                                toastError("Please fill out our pledge and wait for approval before signing up.");
                            } else {
                                if (emailData.confirmed) {
                                    const resp = await register(email, password);
                                    if (resp.error)
                                        toastError("An account has been found with this email. Please log in.");
                                } else {
                                    toastError("We received your pledge. Please wait for approval before signing up.");
                                }
                            }
                        }
                    }}
                    value="SUBMIT"
                />
            </form>
        </div>
    );
}
