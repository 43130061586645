import React, { useContext, useRef, useState } from "react";
import Popup from "reactjs-popup";
import { checkMetadata, toastPromise, uploadFileToBackblaze } from "../../GlobalFunctions.ts";
import imageCompression from "browser-image-compression";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import "../../assets/css/popup.css";
import { AuthContext } from "../../App";

export default function UploadImagePopup({ open, setOpen, sidetabvisible }) {
    const [imgSrc, setImgSrc] = useState("");
    const [uploaddisabled, setUploadDisabled] = useState(false);
    const imgRef = useRef(null);
    const blobUrlRef = useRef("");
    const [crop, setCrop] = useState();
    const [completedCrop, setCompletedCrop] = useState();
    const { user, setUser } = useContext(AuthContext);

    const aspect = 1;

    const closeModal = () => {
        setOpen(false);
        setImgSrc(null);
        setCrop(null);
        setCompletedCrop(null);
        blobUrlRef.current = null;
    };

    function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
        return centerCrop(
            makeAspectCrop({ unit: "%", width: 90 }, aspect, mediaWidth, mediaHeight),
            mediaWidth,
            mediaHeight
        );
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setCrop(undefined); // Makes crop preview update between images.
            const reader = new FileReader();
            reader.onloadend = () => {
                // console.log("reader.result:", reader.result)
                setImgSrc(reader.result);
            };
            // console.log("file:", file)
            reader.readAsDataURL(file);
        }
    };

    function onImageLoad(e) {
        // console.log("e.target.width:", e.target.width, "e.target.height:", e.target.height)
        const crop = centerAspectCrop(e.target.width, e.target.height, aspect);
        // console.log("crop:", crop)
        setCrop(crop);
    }

    async function onDownloadCropClick() {
        const image = imgRef.current;
        if (!image || !completedCrop) {
            throw new Error("Image or crop data is missing");
        }

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        const offscreen = new OffscreenCanvas(completedCrop.width * scaleX, completedCrop.height * scaleY);
        const ctx = offscreen.getContext("2d", { alpha: true });

        if (!ctx) {
            throw new Error("No 2d context");
        }

        // Draw a white background
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, offscreen.width, offscreen.height);

        // Draw a circular mask
        ctx.save();
        ctx.beginPath();
        ctx.arc(
            offscreen.width / 2,
            offscreen.height / 2,
            Math.min(offscreen.width, offscreen.height) / 2,
            0,
            2 * Math.PI
        );
        ctx.closePath();
        ctx.clip();

        ctx.drawImage(
            image,
            completedCrop.x * scaleX,
            completedCrop.y * scaleY,
            completedCrop.width * scaleX,
            completedCrop.height * scaleY,
            0,
            0,
            offscreen.width,
            offscreen.height
        );

        // Restore the context to remove the circular clipping
        ctx.restore();

        const blob = await offscreen.convertToBlob({
            type: "image/jpeg",
            quality: 0.5,
        });

        const compressedFile = await imageCompression(blob, { maxSizeMB: 0.75 });

        const metadata = await checkMetadata();
        return new File([compressedFile], `${metadata.uid}.jpeg`, { type: "image/jpeg" });
    }

    return (
        <div>
            <Popup
                open={open}
                closeOnDocumentClick
                overlayStyle={{
                    background: "rgba(0,0,0,0.5)",
                    marginLeft: sidetabvisible ? "25vw" : 0,
                    width: sidetabvisible ? "75vw" : "100vw",
                }}
                onClose={closeModal}
                className="flex flex-col items-center justify-center"
            >
                <p className="mb-4 text-lg">Change your Profile Picture!</p>
                {imgSrc ? (
                    <ReactCrop
                        crop={crop}
                        onChange={(_, percentCrop) => setCrop(percentCrop)}
                        onComplete={(c) => setCompletedCrop(c)}
                        aspect={aspect}
                        minWidth={400}
                        minHeight={200}
                        maxHeight={500}
                        circularCrop={true}
                        className="mb-4"
                    >
                        <img
                            onLoad={onImageLoad}
                            className="!max-h-[70vh]"
                            ref={imgRef}
                            src={imgSrc}
                            alt="Preview"
                            style={{ transform: `scale(1) rotate(0deg)` }}
                        />
                    </ReactCrop>
                ) : (
                    user?.imglink && (
                        <img src={user?.imglink} className="mb-4" style={{ width: "35%" }} alt="Current Profile" />
                    )
                )}
                <div className="w-full">
                    <div className="w-full">
                        <input
                            type="file"
                            name="profileimage"
                            accept="image/jpeg, image/png, image/jpg"
                            multiple={false}
                            onChange={handleFileChange}
                            className="w-full"
                        />
                        <button
                            disabled={uploaddisabled}
                            onClick={async () => {
                                toastPromise(
                                    new Promise(async (resolve, reject) => {
                                        setUploadDisabled(true);
                                        const file = await onDownloadCropClick();

                                        const folderType = "creators";
                                        const fileName = `${folderType}/${user.uuid}/profilepic.jpeg`;

                                        const uploadresult = await uploadFileToBackblaze(
                                            file,
                                            fileName,
                                            folderType,
                                            user?.imglink ? true : false
                                        );
                                        console.log("uploadresult:", uploadresult);
                                        if (!uploadresult.link) {
                                            reject("Error saving your profile image!");
                                        } else {
                                            setUser((prev) => ({ ...prev, imglink: uploadresult.link }));
                                            resolve();
                                            setImgSrc(null);
                                            closeModal();
                                        }
                                        setUploadDisabled(false);
                                    }),
                                    "Uploading image..."
                                );
                            }}
                            className="bg-[#5D87FF] my-4 text-[1rem] rounded-lg shadow-2xl shadow-cyan-500/50 px-4 py-1 text-white"
                        >
                            Upload
                        </button>
                    </div>
                </div>
            </Popup>
        </div>
    );
}
