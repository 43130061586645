import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../App.js";
import { toastError } from "../GlobalFunctions.ts";
import { supabase } from "../supabase.js";
import { GlobalStyles } from "../GlobalStyles.js";

export default function JobListing() {
    const navigate = useNavigate();
    const [firstname, setFirstName] = useState("");
    const [lastname, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [signupdone, setSignupDone] = useState(false);

    return <div>The Job Listing Screen will go here!!!</div>;
}
