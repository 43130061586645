import styled from "styled-components";

export const Switch = styled.div`
    position: relative;
    height: 26px;
    width: 135px;
    background-color: #00000040;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
    flex-direction: row;
    width: 85%;
    height: 45%;
    border-radius: 14px;
    padding: 0.5rem;
`;

export const SwitchRadio = styled.input`
    display: none;
`;

export const SwitchSelection = styled.span`
    display: block;
    position: absolute;
    z-index: 1;
    width: 45px;
    height: 26px;
    background: #0a2a3e;
    transition: left 0.25s ease-out;
    left: 49%;
    height: calc(90% - 8px);
    /* margin-block: auto; */
    top: 12%;
    width: 50%;
    border-radius: 14px;
`;

export const SwitchLabel = styled.label`
    position: relative;
    z-index: 2;
    float: left;
    width: 45px;
    line-height: 26px;
    font-size: 11px;
    color: rgba(0, 0, 0, 0.6);
    text-align: center;
    cursor: pointer;
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 20px;
    align-items: flex-end;
    gap: 1vw;
    color: #fdf9e1;

    ${SwitchRadio}:checked + & {
        transition: 0.15s ease-out;
    }
`;
