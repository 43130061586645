// import React from 'react';
// import { useState, useEffect, useContext } from 'react'
// import { useNavigate } from 'react-router-dom';
// import { Link } from 'react-router-dom'
import "../assets/css/contact.css";
// import { GlobalStyles } from '../GlobalStyles'

export default function ContactUs() {
    return (
        <div className="contact-container">
            <div className="contact-header">
                <h1>Contact Us</h1>
                <p>We'd love to hear from you! Please fill out the form below to get in touch with us.</p>
            </div>
            <div className="contact-form-container">
                <form className="contact-form">
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input type="text" id="name" name="name" required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" name="email" required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="message">Message</label>
                        <textarea id="message" name="message" rows="5" required></textarea>
                    </div>
                    <button type="submit" className="submit-button">
                        Send Message
                    </button>
                </form>
            </div>
        </div>
    );
}
