import React, { useState, useContext, useEffect } from "react";
import { toastSuccess, toastError } from "../GlobalFunctions.ts";
import { supabase } from "../supabase.js";
import { AuthContext } from "../App.js";

export default function ForgotPassword() {
    const [password, setPassword] = useState("");
    const { reset } = useContext(AuthContext);

    return (
        <div id="authform" className="flex flex-col items-center justify-center bg-white p-8 customshadow rounded-xl">
            <p id="forgottitle">Reset your password</p>

            <input
                value={password || ""}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                type="password"
                autoCapitalize="none"
                autoCorrect="false"
            />

            <input
                id="signupbutton"
                type="button"
                className="actionbtn rounded-3xl !text-white !font-semibold !mt-0"
                onClick={async () => {
                    if (password === "") {
                        toastError("Please fill out all fields!");
                    } else {
                        const resp = await reset(password);
                        if (resp.error) {
                            toastError("There was an error resetting your password.");
                        } else {
                            toastSuccess("Password reset successfully!");
                        }
                    }
                }}
                value="RESET"
            />
        </div>
    );
}
