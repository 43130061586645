import React, { createContext, useEffect, useState } from "react";
import Routing from "./components/global/Routing";
import "./App.css";
import { supabase } from "./supabase";
import { Toaster } from "react-hot-toast";
import { toastError, toastSuccess } from "./GlobalFunctions.ts";
import "./assets/fonts/style.css";

export const AuthContext = createContext();

export default function App() {
    const [user, setUser] = useState(null);
    const [role, setRole] = useState(null);
    const [name, setName] = useState(null);
    const [session, setSession] = useState(null);
    const [sessionchecked, setSessionChecked] = useState(false);

    // useEffect(() => {
    //   console.log("user is now:", user)
    // }, [user])

    return (
        <AuthContext.Provider
            value={{
                user,
                setUser,
                role,
                setRole,
                name,
                setName,
                session,
                setSession,
                sessionchecked,
                setSessionChecked,
                login: async (email, password) => {
                    const res = await supabase.auth.signInWithPassword({ email, password });
                    if (res.error) {
                        console.error(res);
                        toastError(res.error.message);
                    } else {
                        setUser((prev) => ({ ...prev, email }));
                        toastSuccess("Signed in!");
                        window.location.href = "/";
                    }
                },
                register: async (email, password) => {
                    const { data, error } = await supabase.auth.signUp({ email, password });
                    if (error) {
                        console.error(error);
                        return { error };
                    } else {
                        setUser((prev) => ({ ...prev, ...data.user }));
                        toastSuccess("Signed up!");
                        window.location.href = "/";
                        return true;
                    }
                },
                reset: async (newPassword) => {
                    const { error } = await supabase.auth.updateUser({ password: newPassword });
                    if (error) {
                        console.error(error);
                        toastError("There was an error resetting your password.");
                        return { error };
                    } else {
                        // setUser((prev) => ({ ...prev, ...data.user }));
                        toastSuccess("Password reset!");
                        window.location.href = "/";
                        return true;
                    }
                },
                logout: async (deleting) => {
                    if (deleting) {
                        const { error } = await supabase.auth.signOut();
                        if (error) {
                            console.error("Error signing out:", error);
                            toastError("Error signing out. Please try again later.");
                        } else {
                            const { error } = await supabase.rpc("delete_user", {
                                user_email: user.email,
                            });
                            if (error) {
                                console.error("Error deleting user:", error);
                                toastError("Error deleting user. Please try again later.");
                            } else {
                                toastSuccess("Account deleted successfully!");
                                window.location.href = "/";
                            }
                        }
                    } else {
                        await supabase.auth.signOut().then(async (val) => {
                            if (val.error) {
                                toastError(val.error.message);
                            } else {
                                // wipe local storage
                                localStorage.clear();
                                setUser(null);
                                setName(null);
                                window.location.href = "/";
                                toastSuccess("Logged out.");
                            }
                        });
                    }
                },
            }}
        >
            <Routing />
            <Toaster position="top-center" />
        </AuthContext.Provider>
    );
}
