import { useLocation, useNavigate } from "react-router-dom";
import "../assets/css/postsignup.css";
import { toastSuccess } from "../GlobalFunctions.ts";

export default function PostSignUp() {
    const siteLink = window.location.href;
    const location = useLocation();
    const uuid = location.state.uuid;
    const role = location.state.role;
    const navigate = useNavigate();

    return (
        <div id="postsignupbox" className="flex flex-col items-center justify-center">
            <div
                id="innersignupbox"
                className="text-center flex flex-col items-center justify-center bg-white gap-[2vh] rounded-xl customshadow"
            >
                <p id="postsignuptitle" className="text-7xl text-center">
                    THANK YOU!
                </p>
                <p id="postsignupsubtitle">Your account is under review.</p>
                <p className="font-[275]">Invite friends to sign the ABI pledge and skip the waitlist</p>
                <div className="!flex-row !w-full !p-0 overflow-hidden" id="referralsection">
                    <button
                        id="copylink"
                        className="w-1/4 cursor-pointer flex flex-col justify-center items-center"
                        onClick={() => {
                            navigator.clipboard.writeText(siteLink.replace("postsignup", `refer/${uuid}`));
                            toastSuccess("Link copied to clipboard");
                        }}
                    >
                        copy link
                    </button>
                    <div id="referrallink" className="w-3/4">
                        {siteLink.replace("postsignup", `refer/${uuid}`)}
                    </div>
                </div>
                <button
                    id="backtohomebutton"
                    className="actionbtn text-3xl text-center rounded-3xl !text-white !font-semibold w-[70%]"
                    onClick={() => navigate(role === "brand" ? "/brands" : "/")}
                >
                    BACK TO HOME
                </button>
            </div>
        </div>
    );
}
